import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { updateFAQ } from '../../../redux/faq/faq.action';
import { useDispatch } from 'react-redux';

function EditFaqModal({ show, handleEditModal, data }) {

  const dispatch = useDispatch()
  const [faq, setFaq] = useState({
    id: data._id,
    title: data.title,
    description: data.description
  })

  const handleClose = () => {
    setFaq({ ...faq, title: '', description: '' })
    handleEditModal()
  }

  const handleFaqSubmit = () => {
    const data = {
      _id: faq.id,
      title: faq.title,
      description: faq.description
    }
    dispatch(updateFAQ(data))
    handleClose()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className='cms-pages-style'
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton className='faq-modal'>
          <Modal.Title>Manage Faq's</Modal.Title>
          <button
            type='button'
            className='close-btn'
            onClick={handleClose}
            aria-label='Close'
          >
            ×
          </button>
        </Modal.Header>

        <Modal.Body className='faq-modal'>
          <Form>
            <Form.Group className='mb-3' controlId='FaqTitle'>
              <Form.Label>Faq Title</Form.Label>
              <Form.Control
                type='text'
                name='title'
                value={faq.title}
                onChange={(e) => setFaq({ ...faq, title: e.target.value })}
                placeholder='Enter Faq Title'
                required
              />
            </Form.Group>
            <Form.Group>
              <div className="ido-ckeditor setting-ckeditor pt-lg-4 pt-3">
                <CKEditor editor={ClassicEditor} data={faq.description == null ? '' : faq.description} onChange={(event, editor) => {
                  const data = editor.getData();
                  setFaq({ ...faq, description: data })
                }} />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='faq-modal  border-top-0'>
          <Button
            className='blue-imp light-blue-btn text-capitalize'
            type='submit'
            onClick={handleFaqSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditFaqModal