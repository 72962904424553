/* eslint-disable */
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi';
import Swal from 'sweetalert2';
const Banner = () => {
  const navigate = useNavigate()
  const {address} = useAccount();
  const createIdo = () => {
    if(address === '' || address === undefined){
      Swal.fire(
        'Wallet Not Connected!',
        'Please connect your wallet then proceed.',
        'error'
      )
    } else {
      navigate('/admin/managepools')
    }
  }
  return (
    <>
      <section className='hero-banner'>
        <div className='container-fluid custom-block'>
          <div className='banner-text'>
            <div className='text-wrapper'>
              <div className='text-wrapper'>
                <span className='banner-span text-uppercase'>
                  safest launchpad
                </span>
                <h1 className='banner-h1 text-uppercase'>multi-chain</h1>
              </div>
              <p className='light-p'>
                Launching hand-picked high-quality projects on the Blockchain.
              </p>
              <p className='p'>
                Stake MutoPad tokens to get early-access to promising projects.
              </p>
            </div>
            <div className='text-wrapper'>
                <a role='button' className='d-inline-block theme-btn text-capitalize me-3' onClick={createIdo}>
                  <span>Create IDO</span>
                </a>
              {/* <Link className='d-inline shadow-btn text-capitalize' to='admin/managepools'>
              </Link> */}
              <a
                className=' d-inline-block theme-btn text-capitalize'
                href='https://t.me/metamuto'
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Telegram</span>
              </a>
            </div>
          </div>
        </div>
        <section className='market-cap'>
          <div className='container-fluid custom-block'>
              <div className='market-cap-inner m-auto'>
              <div className="titles-sett card-header justify-content-center">
                  <h2 className="text-shadow text-center">Muto – You Can Never Be In Lotss!  </h2>
              </div>
                <blockquote>
                 <div>
                 <div className="titles-sett card-header py-0 mt-3 justify-content-center">
                    <label className="form-label purple text-center">Description/Introductory</label>
              </div>
                 <p className='text-white qoute-p mb-3 my-2'> Looking to get into the world of cryptocurrency investment/fundraising? MutoPad is just the platform for you! Featuring an industry-leading launchpad with pooling, staking, guaranteed allocations, lottery, and more, MutoPad is a decentralized platform on the Ethereum blockchain that’s a perfect way to get started with cryptocurrency investment.</p>
                  <p className='text-white qoute-p'>
                  Based on the crowdfunding technique, this innovative launchpad will enable cryptocurrency projects to introduce native coins/tokens through DEXs (decentralized exchanges).  And with our Initial Dex Offering (IDO), you can be sure to get in on the ground floor of some of the hottest new projects out there. So why wait? Sign up today and see how UltraFinancePad  can help you take your first steps into the exciting crypto world! 
                  </p>
                 </div>
                </blockquote>
                </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Banner
