import { CREATE_CMS_PAGE, CMS_PAGE_LISTING, CMS_PAGE_BY_ID, UPDATE_CMS_PAGE, DELETE_CMS_PAGE, ERROR_STATE, UPDATE_STATE, CMS_SLUG } from './cms.types';
import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';

export const cmsPageListing = () => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/admin/cms/list`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: CMS_PAGE_LISTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
    return res.data.success
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createCMSPage = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/admin/cms/create`, data);
    if (res?.data) {
      let { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: CREATE_CMS_PAGE,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res);
    toast.success(error.res);
  }
};

export const cmsPageById = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/admin/cms/get/${id}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: CMS_PAGE_BY_ID,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCMSPage = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/admin/cms/edit`, data);
    if (res?.data) {
      let { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_CMS_PAGE,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteCMSPage = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('delete', `/v1/admin/cms/delete/${id}`);
    if (res?.data) {
      let { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: DELETE_CMS_PAGE,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATE
    });
  } catch (error) {
    console.log(error);
  }
}

export const cmsSlugPage = (pageTitle) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/admin/cms/slug/${pageTitle}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: CMS_SLUG,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
    return res.data.success
  } catch (error) {
    console.log(error);
    return false;
  }
};
