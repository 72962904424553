import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Row, Col, Form, FormGroup, Button } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Info from '../../InfoBtn/info';
import { useDispatch } from 'react-redux';
import { createCMSPage, updateState } from '../../../redux/cms/cms.action';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const CreateCMSPage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pageDetail, setPageDetails] = useState({
    pageTitle: '',
    pageData: '',
    metaTitle: '',
    metaKeywords: '',
    metaDescription: '',
    showLinkInHeader: false,
    showLinkInFooter: false,
    pageStatus: false,
  })

  const [ckeditorData, setCkeditorData] = useState('')
  const [formValidate, setFormValidate] = useState({})
  const success = useSelector((state) => state.cms?.success)

  const handleChange = e => {
    const { name, value } = e.target
    setPageDetails({ ...pageDetail, [name]: value })

    if (!!formValidate[name]) setFormValidate({
      ...formValidate,
      [name]: null
    })
  }

  const handleLinkInHeader = (e) => {
    let isChecked = e.target.checked;
    setPageDetails({ ...pageDetail, showLinkInHeader: isChecked });
  };

  const handleLinkInFooter = (e) => {
    let isChecked = e.target.checked;
    setPageDetails({ ...pageDetail, showLinkInFooter: isChecked });
  };

  const handleActiveStatus = (e) => {
    let isChecked = e.target.checked;
    setPageDetails({ ...pageDetail, pageStatus: isChecked });
  };

  const findFormErrors = () => {
    const { pageTitle, telegramLink, mediumLink, twitterLink, websiteLink, appLink, podCastLink } = pageDetail
    const newErrors = {}

    const regex = new RegExp(
      "https?://(www.)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    // pageTitle errors
    if (!pageTitle || pageTitle === '') newErrors.pageTitle = 'cannot be blank!'
    if (telegramLink && !regex.test(telegramLink)) newErrors.telegramLink = 'Please Enter a Valid Link!'
    // mediumLink errors
    if (mediumLink && !regex.test(mediumLink)) newErrors.mediumLink = 'Please Enter a Valid Link!'
    // twitterLink errors
    if (twitterLink && !regex.test(twitterLink)) newErrors.twitterLink = 'Please Enter a Valid Link!'
    // websiteLink errors
    if (websiteLink && !regex.test(websiteLink)) newErrors.websiteLink = 'Please Enter a Valid Link!'
    // appLink errors
    if (appLink && !regex.test(appLink)) newErrors.appLink = 'Please Enter a Valid Link!'
    // podCastLink errors
    if (podCastLink && !regex.test(podCastLink)) newErrors.podCastLink = 'Please Enter a Valid Link!'

    return newErrors
  }

  const SaveSettings = () => {
    // const {pageTitle,}

    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormValidate(newErrors)
    } else {
      const keywordArray = pageDetail.metaKeywords?.split(' ');
      const data = { ...pageDetail, pageData: ckeditorData, metaKeywords: keywordArray }
      dispatch(createCMSPage(data))
    }
  }

  useEffect(() => {
    if (success) {
      dispatch(updateState())
      navigate('/admin/manage-cmspages')
    }
  }, [success, navigate, dispatch]);

  return (
    <div className='mutopad-w-100'>
      <div className='content'>
        <div className='cms-pg'>
          <Card className='pt-lg-4 pt-3 h-100' id="style-6">
            <Card.Header className='titles-sett text-center'>
              <div className='d-flex flex-sm-row flex-column justify-content-sm-between w-100'>
                <h2 className='text-shadow mb-sm-0 mb-2'> Create CMS Page</h2>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='cms-pages-style'>
                <div className='container'>
                  <Row>
                    <Col sm={12}>
                      <div className='cms-pages-style text-secondary'>
                        <Form>
                          <Form.Group className='mb-2'>
                            <Form.Label>Page Title</Form.Label>
                            <Form.Control
                              type='text'
                              name='pageTitle'
                              onChange={handleChange}
                              placeholder='Enter Name'
                              className={'text-white'}
                              value={pageDetail.pageTitle}
                              isInvalid={!!formValidate.pageTitle}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.pageTitle}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                            <Form.Label>Page Description</Form.Label>
                            <span className="ido-ckeditor setting-ckeditor">
                              <CKEditor editor={ClassicEditor} data={ckeditorData == null ? '' : ckeditorData} onChange={(event, editor) => { setCkeditorData(editor.getData()) }} />
                            </span>
                            <Form.Control.Feedback type='invalid'>{formValidate.pageData}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                            <Form.Label>Meta Title</Form.Label>
                            <Form.Control
                              type='text'
                              name='metaTitle'
                              className={'text-white'}
                              onChange={handleChange}
                              placeholder='Enter metaTitle'
                              value={pageDetail.metaTitle}
                              isInvalid={!!formValidate.metaTitle}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.metaTitle}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Meta keywords</Form.Label>
                              <Form.Control
                                type='text'
                                name='metaKeywords'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter metaKeywords'
                                value={pageDetail.metaKeywords}
                                isInvalid={!!formValidate.metaKeywords}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.metaKeywords}</Form.Control.Feedback>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className='mb-3'>
                            <Form.Label>Meta Description</Form.Label>
                            <Form.Control id="scroll-1"
                              as='textarea'
                              rows={2}
                              name='metaDescription'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Description'
                              value={pageDetail.metaDescription}
                              isInvalid={!!formValidate.metaDescription}
                            />
                            <Form.Control.Feedback type='invalid'>{formValidate.metaDescription}</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className='mb-3'>
                            <Row>
                              <FormGroup className='col-4'>
                                <Info name={"Show Link In Header"} desc={"Show Link In Header "} />
                                <div className="switch">
                                  <input id="switch-1" type="checkbox" className="switch-input"
                                    checked={pageDetail.showLinkInHeader} onChange={(e) => handleLinkInHeader(e)} />
                                  <label htmlFor="switch-1" className="switch-label" style={{ padding: "0px" }}></label>
                                </div>
                              </FormGroup>
                              <FormGroup className='col-4'>
                                <Info name={"Show Link In Footer"} desc={"Show Link In Footer "} />
                                <div className="switch">
                                  <input id="switch-2" type="checkbox" className="switch-input"
                                    checked={pageDetail.showLinkInFooter} onChange={(e) => handleLinkInFooter(e)} />
                                  <label htmlFor="switch-2" className="switch-label" style={{ padding: "0px" }}></label>
                                </div>
                              </FormGroup>
                              <FormGroup className='col-4'>
                                <Info name={"Active"} desc={"Active or Deactivate"} />
                                <div className="switch">
                                  <input id="switch-3" type="checkbox" className="switch-input"
                                    checked={pageDetail.pageStatus} onChange={(e) => handleActiveStatus(e)} />
                                  <label htmlFor="switch-3" className="switch-label" style={{ padding: "0px" }}></label>
                                </div>
                              </FormGroup>
                            </Row>
                          </Form.Group>
                          <div className="btn-wrapper border-0 pt-4">
                            <Button className="light-blue-btn w-10" onClick={SaveSettings}>Create Page</Button>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateCMSPage