/* eslint-disable */
import React, { useState, useEffect, useCallback, createRef } from 'react';
import { Row, Container, Modal, Accordion, Table, Form, Button, Image } from 'react-bootstrap';
import smallLogo from '../../assets/images/smalllogo.png';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'react-bootstrap';
import './productinfo.css';
import { BigNumber, ethers } from 'ethers';
import { useParams } from 'react-router-dom';
import TokenAbi from '../../utils/token20Abi';
import ContractAbi from '../../utils/contractAbi.json';
import ethContractAbi from '../../utils/ethContractAbi.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DefualtImageSetter, gDecodeOrder, gFindClearingPrice, toWeiValue, calculateClearingPrice } from '../../utils/globalHelpers';
import accessIcon from '../../../src/assets/images/access.png';
import OrderDetail from '../OrderDetails/orderdetail';
import moment from 'moment';
import IDOClock from './idoClock';
import ModalLoading from './loadingModal';
import Web3 from 'web3';
import { decode } from 'js-base64';
import { useDispatch, useSelector } from 'react-redux';
import { ordersListing, placePoolOrder, poolById, settelPoolCreate, createClaimedOrder, updateState, addToWhiteList, removeWhiteList, getWhiteListAddresses } from '../../redux/pool/pool.action';
import scamAlert from '../../../src/assets/images/scam-alert.png';
import scamAlertsmall from '../../../src/assets/images/scamsmall.png';
import { faCheck, faExclamation, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import KYCMessagePage from './kycMessage';
import { addComment, commentsByPoolId } from '../../redux/comment/comment.action';
import Swal from 'sweetalert2';
import { useAccount, useNetwork } from 'wagmi';
import { platformSupportedChains } from '../../config/config';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import ReactPaginate from 'react-paginate';
import FullPageLoader from '../Loaders/fullpageloader';
import { getRecieptData } from '../../utils/globalHelpers';
import io from 'socket.io-client';
const socket = io.connect('http://localhost:5000');

const ProductInfo = () => {
  //** libraries & modules **//
  const web3 = new Web3(window.ethereum);
  const { id } = useParams();
  let { poolId } = useParams();
  poolId = decode(poolId);
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { chain } = useNetwork();

  //** all redux states **//
  const pool = useSelector(state => state.pool?.pool);
  const orders = useSelector(state => state.pool.orders);
  const claimStatus = useSelector(state => state.pool.claimStatus);
  const success = useSelector(state => state.pool.success);
  const settlePoolStatus = useSelector(state => state.pool.settlePoolStatus);
  const poolsComments = useSelector(state => state.comment?.commentsByPoolId);
  const whiteListAddresses = useSelector(state => state?.pool?.whiteListAddresses);
  const whiteListAddressesData = useSelector(state => state?.pool?.whiteListAddresses);
  const commentSuccess = useSelector(state => state?.comment?.commentSuccess);

  //** all state variables **//
  const [remainTime, setRemainTime] = useState({ day: '' });
  const [isOrder, setIsOrder] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [apTok1, setApTok1] = useState(false);
  const [apTok2, setApTok2] = useState(false);
  const [bidBalErr, setBidBalErr] = useState('');
  const [poolBalErr] = useState('');
  const [commentShow, setCommentShow] = useState(false);
  const [commentMsg, setCommentMsg] = useState('');
  const [commentError, setCommentError] = useState('');
  const [chainLogo, setChainLogo] = useState('');
  const [chainName, setChainName] = useState('');
  const [spinLoader, setSpinLoader] = useState(true);
  const [poolStarted, setPoolStarted] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [userLoginAcc, setUserLoginAcc] = useState('');
  const [initialOrder, setInitialOrder] = useState({});
  const [clearingPriceOrder, setClearingPriceOrder] = useState({});
  const [clearingPrice, setClearingPrice] = useState(null);
  const [initialPrice, setInitialPrice] = useState(null);
  const [thresholdNotReached, setThresholdNotReached] = useState(true);
  //eslint-disable-next-line
  const [item, setItem] = useState({
    idoTokenSymbol: '',
    poolingToken: '',
    poolId: '',
    hardCap: '',
    startDate: '',
    endDate: '',
    price: '',
    totalSupply: '',
    biddingToken: '',
    idoAddress: '',
    logoHash: '',
    infoHash: '',
    isDeleted: '',
    name: '',
    status: '',
    network: '',
    socials: [],
    remainTime: {
      day: ' ',
    },
  });
  const [biddTokenInfo, setBiddTokenInfo] = useState({ name: '', decimals: 1, symbol: '' });
  const [bidPerUser, setBidPerUser] = useState(0);
  const [orderBuyLoader, setOrderBuyLoader] = useState(false);
  const [orderSellLoader, setOrderSellLoader] = useState(false);
  const [previousRatio, setPreviousRatio] = useState(0);
  const [buyOrderErr, setBuyOrderErr] = useState('');
  const [sellOrderErr, setSellOrderErr] = useState('');
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [poolBalance, setPoolBalance] = useState('');
  const [balan, setBal] = useState(0);
  const [poolOrder, setPoolOrder] = useState({ buyAmount: '', sellAmount: '' });
  const [percen, setPercent] = useState(0);
  const [errors, setErrors] = useState('');
  const [approveErr, setApproveErr] = useState('');
  const [bidSellErr, setBidSellErr] = useState('')
  const offsetInSeconds = new Date().getTimezoneOffset() * 60 * -1;

  //** all useEffects & dependencies **//
  useEffect(() => {
    socket.on('orderPlaced', data => {
      setModalShow(false);
      setApTok1(false);
      setApTok2(false);
      setModalSuccess(false);
      // Swal.fire('Order is placed!', 'please refresh page so new changes should be made!', 'success');
      dispatch(poolById(poolId));
    });
  }, [socket]);

  useEffect(() => {
    socket.on('poolCreated', data => {
      setModalShow(false);
      setApTok1(false);
      setApTok2(false);
      setModalSuccess(false);
      // Swal.fire('Pool Created', 'please refresh page so new changes should be made!', 'success');
    });
  }, [socket]);

  useEffect(() => {
    socket.on('poolCleared', data => {
      setLoading(false);
      // Swal.fire('Pool is Cleared!', 'please refresh page so new changes should be made!', 'success');
      // dispatch(poolById(poolId));
    });
  }),
    [socket];

  useEffect(() => {
    if (poolId) {
      getContractData(poolId);
      dispatch(poolById(poolId));
      setSpinLoader(false);
      localStorage.setItem('hitApi', false);
    }
  }, [poolId, dispatch]);

  useEffect(() => {
    if (userLoginAcc) {
      dispatch(poolById(poolId));
    }
  }, [userLoginAcc]);

  useEffect(() => {
    if (poolId) {
      dispatch(getWhiteListAddresses(poolId));
    }
  }, [poolId]);

  useEffect(() => {
    let today = Math.floor(Date.now() / 1000);
    if (pool?.poolEndDate !== undefined && pool?.poolEndDate !== 0) {
      let result = (parseInt(pool?.poolEndDate) - parseInt(today)) * 1000;
      if (result > 0) {
        const timer = setTimeout(() => {
          dispatch(poolById(poolId));
        }, result);
        return () => clearTimeout(timer);
      }
    }
  }, [poolId, pool]);

  useEffect(() => {
    if (pool?._id) {
      dispatch(ordersListing(pool?._id));
    }
  }, [pool?._id, dispatch, isOrder, isClaimed]);

  useEffect(() => {
    if (success || settlePoolStatus) {
      setLoading(false);
      setModalShow(false);
      setSpinLoader(false);
      dispatch(ordersListing(pool?._id));
      dispatch(updateState());
    }
  }, [success, pool?._id, dispatch, settlePoolStatus]);

  useEffect(() => {
    if (claimStatus === true) {
      setLoading(false);
      setSpinLoader(false);
      dispatch(ordersListing(pool?._id));
      dispatch(updateState());
    }
  }, [claimStatus, dispatch, pool?._id]);

  useEffect(() => {
    if (success) {
      dispatch(poolById(poolId));
    }
  }, [success, poolId, dispatch]);

  useEffect(() => {
    calculateTime();
    let lastBidbuy = orders?.length > 0 ? orders[orders?.length - 1]?.minBuyAmounts : pool?.minimumBuyAmount;
    let lastBidSell = orders?.length > 0 ? orders[orders?.length - 1]?.sellAmounts : pool?.minimumBiddingAmount;
    let lastBidRatio = lastBidSell / lastBidbuy;
    setPreviousRatio(lastBidRatio);
    // eslint-disable-next-line
  }, [pool]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(orders?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(orders?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, orders]);

  useEffect(() => {
    if (poolId) {
      dispatch(commentsByPoolId(poolId));
    }
  }, [poolId, dispatch]);

  useEffect(() => {
    const chainContract = platformSupportedChains[chain?.id];
    if (chainContract) {
      setChainLogo(chainContract.logo);
      setChainName(chainContract.name);
    }
  }, [chain?.id]);

  useEffect(() => {
    if (commentSuccess) {
      setCommentMsg('');
      setCommentError('');
      setCommentShow(false);
      dispatch(commentsByPoolId(poolId));
      dispatch(updateState());
    }
  }, [poolId, commentSuccess, dispatch]);

  useEffect(() => {
    biddingTokenBalance();
  }, [pool?.bdSymbol]);

  //** all logical functions handlers **//
  const contractHandler = (Abi, tokenAdd) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(Abi, tokenAdd);
    return contract;
  };

  async function getContractData(poolId) {
    let orderObject = {};
    const chainContract = platformSupportedChains[chain?.id];
    const mutoPool = contractHandler(chain?.id == 5 ? ethContractAbi : ContractAbi, chainContract?.poolContract);
    const initialOrder = await mutoPool.methods.poolData(poolId).call();
    orderObject.initialOrder = await initialOrder[3];
    orderObject.clearingOrder = await initialOrder[6];
    let res = await decodeOrder(orderObject?.clearingOrder);
    let clrearPrice = res?.sellAmount / res?.buyAmount;
    setClearingPrice(clrearPrice);
    let res1 = await decodeOrder(orderObject?.initialOrder);
    let initPrice = res1?.sellAmount / res1?.buyAmount;
    setInitialPrice(initPrice);
    setClearingPriceOrder(orderObject.clearingOrder);
    setInitialOrder(orderObject.initialOrder);
    setThresholdNotReached(initialOrder[1].minFundingThresholdNotReached);
    return orderObject;
  }

  async function decodeOrder(bytes) {
    if (bytes === undefined) {
      return {
        userId: 0,
        sellAmount: 0,
        buyAmount: 0,
      };
    }
    let userId = BigNumber.from('0x' + bytes.substring(2, 18)).toString();
    let sellAmount = BigNumber.from('0x' + bytes.substring(43, 66)).toString();
    let buyAmount = BigNumber.from('0x' + bytes.substring(19, 42)).toString();
    return {
      userId,
      sellAmount,
      buyAmount,
    };
  }

  function hasLowerClearingPrice(order1, order2) {
    if (order1.buyAmount * order2.sellAmount < order2.buyAmount * order1.sellAmount) return -1;
    if (order1.buyAmount * order2.buyAmount) return -1;
    if (order1.buyAmount * order2.sellAmount == order2.buyAmount * order1.sellAmount) {
      if (order1.userId < order2.userId) return -1;
    }
    return 1;
  }

  const findClearingPrice = (sellOrders, order) => {
    const { sellAmount, buyAmount } = decodeOrder(order);
    let initialAuctionOrder = {};
    initialAuctionOrder.sellAmount = sellAmount;
    initialAuctionOrder.buyAmount = buyAmount;
    sellOrders.forEach((order, index) => {
      if (index > 1) {
        if (!hasLowerClearingPrice(sellOrders[index - 1], order)) {
          throw Error('The orders must be sorted');
        }
      }
    });
    let totalSellVolume = 0;
    for (const order of sellOrders) {
      totalSellVolume = totalSellVolume + order?.sellAmount;
      if ((totalSellVolume * order.buyAmount) / order.sellAmount > initialAuctionOrder.sellAmount) {
        const coveredBuyAmount = ((initialAuctionOrder.sellAmount - totalSellVolume - order.sellAmount) * order.buyAmount) / order.sellAmount;
        const sellAmountClearingOrder = (coveredBuyAmount * order.sellAmount) / order.buyAmount;
        if (sellAmountClearingOrder > 0) {
          return order;
        } else {
          let res = (order[0]?.sellAmount * totalSellVolume) / (initialAuctionOrder?.sellAmount - order?.sellAmount);
          return res;
        }
      }
    }
    // otherwise, clearing price is initialAuctionOrder
    if (totalSellVolume > initialAuctionOrder.buyAmount) {
      let res = (sellOrders[0].sellAmount * totalSellVolume) / initialAuctionOrder.sellAmount / 1e18;
      return res;
    } else {
      let res = (sellOrders[0].sellAmount * initialAuctionOrder.buyAmount) / initialAuctionOrder.sellAmount / 1e18;
      return res;
    }
  };

  const calculateTime = async () => {
    if (pool) {
      const remainHour = await GetRemainDays(pool?.poolEndDate);
      setRemainTime(remainHour);
    }
  };

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % orders?.length;
    setItemOffset(newOffset);
  };

  const { inputRef } = createRef();

  const biddingTokenBalance = async () => {
    const chainContract = platformSupportedChains[chain?.id];
    let addr = '';
    chainContract?.stableCoins.forEach(item => {
      if (pool?.bdSymbol !== '' || undefined) {
        if (item.name === pool?.bdSymbol) {
          addr = item.address;
        }
      }
    });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const tokenContract = new ethers.Contract(addr, TokenAbi, provider);
    const balance = (await tokenContract.balanceOf(provider.getSigner().getAddress())).toString();
    setTokenBalance(balance);
  };

  const onWheel = () => {
    inputRef.current.blur();
    setTimeout(() => inputRef.current.focus(), 100);
  };

  const GetRemainDays = async arg => {
    var unix = Math.round(+new Date() / 1000);
    var date1 = unix;
    var date2 = arg;
    // To calculate the time difference of two dates
    var Difference_In_Time = date2 - date1;
    var remainHour = {};
    var days = Math.floor(Difference_In_Time / 86400);
    var hours = Math.floor(days / 60);
    if (days < 0) {
      remainHour.day = 0;
      if (remainHour.hours <= 0) {
        remainHour.hours = 0;
      }
    } else {
      remainHour.day = days;
      remainHour.hours = hours;
    }
    return remainHour;
  };

  const FetchProvider = async (tokenAdd, Abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum || process.env.REACT_APP_TESTNET_RPC_URL);
    const signer = provider.getSigner();
    var address = tokenAdd;
    var contract = new ethers.Contract(address, Abi, signer);
    return contract;
  };

  const GetBiddingTokenInfo = async args => {
    const contract = await FetchProvider(args.biddingToken, TokenAbi);
    const Decimals = parseInt(await contract.decimals());
    const decimalConvert = Math.pow(10, Decimals);
    const name = await contract.name();
    const symbol = await contract.name();
    setBiddTokenInfo({
      ...biddTokenInfo,
      name: name,
      decimals: decimalConvert,
      symbol: symbol,
    });
    return decimalConvert;
  };

  const CheckApprove = useCallback(
    async args => {
      /*BALANCE SET*/
      const contract = await FetchProvider(args.biddingToken, TokenAbi);
      var balanPool = await contract.balanceOf(address?.toLocaleLowerCase());
      const decimal = await GetBiddingTokenInfo(args);
      const balDeci = (balanPool / decimal).toFixed(3);
      setBal(balDeci);
      /*BALANCE SET*/

      const allowance = await contract.allowance(address?.toLocaleLowerCase(), args.idoAddress);
      if (parseInt(allowance.toString()) <= 0) {
        // if not allowance then go for approve
        setApproveErr('Wait ! it takes some time for Approval');
      } else {
        setApproveErr('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address],
  );

  const handlechangeBuy = e => {
    let currentBidBuy = e.target.value;
    // setOrderBuyLoader(true);
    if (Number(e.target.value) < 0 || Number(e.target.value) === 0 || Number(pool?.minimumBuyAmount) > Number(e.target.value) || parseFloat(currentBidBuy) > parseFloat(pool?.poolSellAmount)) {
      setBuyOrderErr('Please Enter Valid Buy Amount');
    }
    // if(parseInt(currentBidBuy) > parseInt(pool?.poolSellAmount)){
    //   setBuyOrderErr('Could not buy max than total sell tokens');
    // }
    else {
      setBuyOrderErr('');
      // setOrderBuyLoader(false);
    }
    setPoolOrder({
      ...poolOrder,
      buyAmount: currentBidBuy,
    });
  };

  const handlechangeSell = e => {
    let currentPri = e.target.value;
    setOrderSellLoader(true);
    let lastBidbuy = orders?.length > 0 ? orders[orders?.length - 1]?.minBuyAmounts : pool?.minimumBuyAmount;
    let lastBidSell = orders?.length > 0 ? orders[orders?.length - 1]?.sellAmounts : pool?.minimumBiddingAmount;
    let lastBidRatio = lastBidSell / lastBidbuy;
    let currentbidRatio = e.target.value / poolOrder.buyAmount;
    if (Number(currentbidRatio) < Number(previousRatio)) {
      setBidSellErr('Bid Ratio is less than previous bid ratio');
    }
    if (Number(e.target.value) < 0 || Number(e.target.value) === 0 || Number(pool?.sellAmount) > Number(e.target.value)) {
      setBidSellErr('Please enter valid number');
    } else {
      setErrors('');
      setBidSellErr('');
      setOrderSellLoader(false);
    }
    setPoolOrder({
      ...poolOrder,
      sellAmount: currentPri,
    });
  };

  const fetchBal = async addresss => {
    let TokenData = {};
    const contract = await FetchProvider(addresss, TokenAbi);
    const Decimals = parseInt(await contract.decimals());
    const symbol = await contract.symbol();
    const name = await contract.name();
    const count = parseInt(await contract.balanceOf(address?.toLocaleLowerCase()));
    const balInDecimals = (count / Math.pow(10, Decimals)).toFixed(3);
    setPoolBalance(balInDecimals);
    TokenData.decimals = Decimals;
    TokenData.symbol = symbol;
    TokenData.name = name;
    return TokenData;
  };

  const submitPoolOrder = async e => {
    let lastBidbuy = orders?.length > 0 ? orders[orders?.length - 1]?.minBuyAmounts : pool?.minimumBuyAmount;
    let lastBidSell = orders?.length > 0 ? orders[orders?.length - 1]?.sellAmounts : pool?.minimumBiddingAmount;
    let lastBidRatio = lastBidSell / lastBidbuy;
    let currentbidRatio = poolOrder.sellAmount / poolOrder.buyAmount;
    if (orders?.length > 0 ? Number(currentbidRatio) <= Number(lastBidRatio) : Number(currentbidRatio) < Number(lastBidRatio)) {
      setErrors('Bid Ratio is less than previous bid ratio');
    } else {
      await fetchBal(pool?.biddingToken);
      setErrors('');
      setModalShow(true);
      let gtd = await getContractData(pool?.poolId);
      const chainContract = platformSupportedChains[chain.id];
      const mutoPool = contractHandler(chain?.id == 5 ? ethContractAbi : ContractAbi, chainContract.poolContract);
      const tokenContract = new web3.eth.Contract(TokenAbi, pool?.biddingToken);
      const balanceCheck = await tokenContract.methods.balanceOf(address?.toLocaleLowerCase()).call();
      if (parseInt(balanceCheck) > poolOrder?.sellAmount) {
        const allowanceCheck = await tokenContract.methods.allowance(address?.toLocaleLowerCase(), chainContract.poolContract).call();
        let result;
        if (parseInt(allowanceCheck) <= 0) {
          try {
            result = await tokenContract.methods
              .approve(chainContract.poolContract, '115792089237316195423570985008687907853269984665640564039457584007913129639935')
              .send({ from: window.ethereum.selectedAddress });
          } catch (e) {
            setModalShow(false);
            Swal.fire('Transaction Failed!', 'Please try again', 'error');
          }
        }
        if (parseInt(allowanceCheck) >= 0 || result.blockHash) {
          setApTok1(true);
          try {
            let poolId = pool?.poolId;
            let buyAmt = web3.utils.toWei(poolOrder?.buyAmount, 'ether');
            let sellAmt = web3.utils.toWei(poolOrder?.sellAmount, 'ether');
            let buyAmount = [buyAmt.toString()];
            let sellAmount = [sellAmt.toString()];
            const resultData = await findClearingPrice([{ buyAmount, sellAmount }], pool?.closeStatus === false ? initialOrder : clearingPriceOrder);
            let previousSellOrders = ['0x0000000000000000000000000000000000000000000000000000000000000001'];
            let merkleProof = ['0x0000000000000000000000000000000000000000000000000000000000000000'];
            let res = await mutoPool.methods.placeSellOrders(poolId, merkleProof, buyAmount, sellAmount, previousSellOrders).send({ from: window.ethereum.selectedAddress });
            if (res.blockHash) {
              let dataObj = {
                poolId: pool?._id,
                minBuyAmounts: poolOrder?.buyAmount,
                sellAmounts: poolOrder?.sellAmount,
                prevSellOrders: previousSellOrders[0],
                orderBy: address?.toLocaleLowerCase(),
                blockNumber: res.blockNumber,
                txHash: res.transactionHash,
                bdTokenSymbol: pool?.bdSymbol,
                ptDecimals: pool?.ptDecimals,
                bdDecimals: pool?.bdDecimals,
                network: pool?.network,
                minFundingThreshold: pool?.minimumFundingThreshold,
                thresholdCounter: orders?.length > 0 ? parseInt(orders[orders?.length - 1]?.thresholdCounter) + parseInt(poolOrder?.sellAmount) : poolOrder?.sellAmount,
                tokenSoldCounter: orders?.length > 0 ? parseInt(orders[orders?.length - 1]?.tokenSoldCounter) + parseInt(poolOrder?.buyAmount) : poolOrder?.buyAmount,
                userId: res.events.NewSellOrderPlaced.returnValues.userId,
                blockchainPoolId: res.events.NewSellOrderPlaced.returnValues.poolId,
                initialPrice: initialPrice,
                sellOrders: [{ sellAmount: sellAmt.toString(), buyAmount: buyAmt.toString() }],
                clearingPrice: clearingPrice,
              };
              let resdt = dispatch(placePoolOrder(dataObj));
              setPoolOrder({
                ...poolOrder,
                buyAmount: '',
                sellAmount: '',
              });
              if (resdt) {
                setApTok2(true);
                // setModalSuccess(true);
                setIsOrder(true);
              }
              setModalShow(false);
              setApTok1(false);
              setApTok2(false);
            }
          } catch (e) {
            setModalShow(false);
            Swal.fire('Transaction Failed!', 'Please try again', 'error');
          }
        }
      } else {
        setModalShow(false);
        Swal.fire('Transaction Failed!', 'Do not have enough tokens. Please deposit then try again', 'error');
      }
    }
  };

  const submitClaim = async (e, index, poolId, userId, status) => {
    setLoading(true);
    let chainContract = platformSupportedChains[chain.id];
    let mutoPool = contractHandler(chain?.id == 5 ? ethContractAbi : ContractAbi, chainContract.poolContract);
    // let buyAmount = toWeiValue(parseInt(orders[index]?.minBuyAmounts), parseInt(pool?.ptDecimals));
    // let sellAmount = toWeiValue(parseInt(orders[index]?.sellAmounts), parseInt(pool?.bdDecimals));
    let buyAmount = web3.utils.toWei(orders[index]?.minBuyAmounts, 'ether');
    let sellAmount = web3.utils.toWei(orders[index]?.sellAmounts, 'ether');
    let uId = userId;
    let res = await mutoPool.methods.getEncodedOrder(parseInt(uId), buyAmount.toString(), sellAmount.toString()).call();

    if (res) {
      try {
        let byteOrderClaim = [`${res.toString()}`];
        let byteOrderRefund = await res.toString();
        let id = await poolId;
        let res1;
        if (status === true) {
          res1 = await mutoPool.methods.claimFromParticipantOrder(id, byteOrderClaim).send({ from: window.ethereum.selectedAddress });
        }
        if (status === false) {
          res1 = await mutoPool.methods.refundOrder(id, byteOrderRefund).send({ from: window.ethereum.selectedAddress });
        }
        let claimTrxReslt = await getRecieptData(res1?.transactionHash, orders[index]?.bdTokenSymbol);
        if (res1.blockHash) {
          let newObj = {
            poolId: orders[index].poolId,
            orderId: orders[index]._id,
            claimStatus: true,
            userId: userId,
            claimedBuyAmount: res1?.events?.OrderClaimedByUser?.returnValues.buyAmount,
            claimedSellAmount: res1?.events?.OrderClaimedByUser?.returnValues.sellAmount,
            claimTrxHash: res1.transactionHash,
            bdTokenSymbol: orders[index]?.bdTokenSymbol,
            trxStats: claimTrxReslt,
          };
          dispatch(createClaimedOrder(newObj));
          setIsClaimed(true);
        }
      } catch (e) {
        setLoading(false);
        Swal.fire('Transaction Failed!', 'Claim/Refund transaction has been reverted.', 'error');
      }
    }
  };

  const closeClearPool = async e => {
    setLoading(true);
    e.preventDefault();
    let PoolId = pool?.poolId;
    let gtd = await getContractData(pool?.poolId);
    let chainContract = platformSupportedChains[chain.id];
    let mutoPool = contractHandler(chain?.id == 5 ? ethContractAbi : ContractAbi, chainContract.poolContract);
    if (PoolId !== undefined && PoolId !== '' && errors.length === 0) {
      try {
        let res = await mutoPool.methods.settlePool(parseInt(PoolId)).send({ from: window.ethereum.selectedAddress });
        if (res.blockHash) {
          let newObj = {
            poolId: pool?._id,
            from: res?.from,
            to: res?.to,
            blockHash: res?.events?.PoolCleared?.blockHash,
            blockNumber: res?.events?.PoolCleared?.blockNumber,
            event: res?.events?.PoolCleared?.event,
            id: res?.events?.PoolCleared?.id,
            bnPoolId: res?.events?.PoolCleared?.returnValues?.poolId,
            soldBiddingTokens: res?.events?.PoolCleared?.returnValues?.soldBiddingTokens,
            soldPoolingTokens: res?.events?.PoolCleared?.returnValues?.soldBiddingTokens,
            transactionHash: res?.events?.PoolCleared?.returnValues?.transactionHash,
            transactionIndex: res?.events?.PoolCleared?.returnValues?.transactionIndex,
            clearingPrice: clearingPrice,
            initialPrice: initialPrice,
            orderIndex: orders?.length > 0 ? orders[orders?.length - 1]?._id : '',
          };
          let gtd = await getContractData(pool?.poolId);
          let resd = dispatch(settelPoolCreate(newObj));
          if (resd) {
            setLoading(false);
            localStorage.removeItem('dataKey');
          }
        }
        Swal.fire('Pool Cleared', 'Successfull.', 'success');
        setErrors('');
      } catch (e) {
        setLoading(false);
        Swal.fire('Transaction Failed!', 'Settle Pool transaction has been reverted.', 'error');
      }
    }
    // }
  };
  // Post Comment Handler
  const handlePostComment = async () => {
    // add validation
    const regexp = /^[^\s]/;
    if (commentMsg === '') {
      setCommentError('Please enter a comment');
    } else if (!regexp.test(commentMsg)) {
      setCommentError('Space not allowed at start');
    } else {
      // setCommentShow(false);
      setLoading(true);
      var time = new Date();
      var stime = Date.parse(time);
      const timeF = stime / 1000;
      let correctTime = timeF;
      // let correctTime = time;
      const data = {
        poolId: poolId,
        message: commentMsg,
        messageTime: correctTime,
        userAddress: address?.toLocaleLowerCase(),
      };
      dispatch(addComment(data));
      setLoading(false);
      setCommentMsg('');
    }
  };

  const emergencyWithdraw = async (e, index, poolId, userId) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: 'Emergency withdrawal takes your contribution (with 10% penalty) out of Presale Pool and cancel your participation from Pool!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, withdraw!',
      cancelButtonText: 'Close',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          let chainContract = platformSupportedChains[chain.id];
          let mutoPool = contractHandler(chain?.id == 5 ? ethContractAbi : ContractAbi, chainContract.poolContract);
          // let buyAmount = toWeiValue(parseInt(orders[index]?.minBuyAmounts), parseInt(pool?.ptDecimals));
          // let sellAmount = toWeiValue(parseInt(orders[index]?.sellAmounts), parseInt(pool?.bdDecimals));
          let buyAmount = web3.utils.toWei(orders[index]?.minBuyAmounts, 'ether');
          let sellAmount = web3.utils.toWei(orders[index]?.sellAmounts, 'ether');
          let uId = userId;
          let res = await mutoPool.methods.getEncodedOrder(parseInt(uId), buyAmount.toString(), sellAmount.toString()).call();
          let byteOrderCancel = [`${res.toString()}`];
          let res1 = await mutoPool.methods.cancelSellOrders(poolId, byteOrderCancel).send({ from: window.ethereum.selectedAddress });

          if (res1.blockHash) {
            let newObj = {
              poolId: orders[index].poolId,
              orderId: orders[index]._id,
              claimStatus: true,
              userId: userId,
              claimedBuyAmount: res1?.events?.OrderClaimedByUser?.returnValues.buyAmount,
              claimedSellAmount: res1?.events?.OrderClaimedByUser?.returnValues.sellAmount,
              claimTrxHash: res1.transactionHash,
              isEWD: true,
            };
            let resdt = dispatch(createClaimedOrder(newObj));
            setIsClaimed(true);
            if (resdt) {
              setLoading(false);
              Swal.fire('Emergency Withdraw!', 'Order Cancelled Successfully.', 'success');
            }
          }
        } catch (e) {
          setLoading(false);
          Swal.fire('Transaction Failed!', 'Cancel transaction has been reverted.', 'error');
        }
      }
    });
  };

  const htmlFormater = content => {
    return { __html: content };
  };

  const timeZone = 'America/New_York';
  // Create a new Date object
  const date = new Date();
  
  // Get the UTC time in the specified time zone
  const utcTime = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  console.log(`datatatat---`,Date.now())

  const now = new Date();
  const utcNow = new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  ));
  console.log("🚀 ~ file: ProductInfo.js:796 ~ utcNow:", utcNow)
  
  // getRecieptData("0xebae2553e5a6f0a5a2810708eb65c87aed07fbea9ffa2d2283722eaa32e22f21", 'USDC')
  return (
    <>
      {pool && Object.keys(pool).length !== 0 ? (
        <>
          <section className='section_padding' id='productInfo'>
            {((pool?.poolStatus === undefined || pool?.poolStatus === false) && pool?.poolOwner === address?.toLocaleLowerCase()) || pool?.poolOwner === process.env.REACT_APP_OWNER_ADDRESS ? (
              <div className='container position-relative'>
                <div className='product-info-section position-absolute'>
                  <div className='profile-info-scam-text-content'>
                    <div className='d-flex align-items-center justify-content-between mb-4'>
                      <h5>KYC Disclaimer Notice</h5>
                      <div className='scamsmall-img'>
                        <Image src={accessIcon} className='img-fluid' />
                      </div>
                    </div>
                    <p>Currently KYC is disabled from the admin, To continue this feature please contact with the admin.</p>
                    <h6>{/* <span>Note: </span>This message is from admin side */}</h6>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {pool?.isCancelled === true && pool?.poolStatus === true ? (
              <>
                <div className='container position-relative'>
                  <div className='product-info-section position-absolute'>
                    <div className='profile-info-scam-text-content'>
                      <div className='d-flex align-items-center justify-content-between mb-4'>
                        <h5>Pool Status Notice</h5>
                        <div className='scamsmall-img'>
                          <Image src={accessIcon} className='img-fluid' />
                        </div>
                      </div>
                      <p>Currently Pool is Cancelled from the admin, You can withdraw/refund you invested Tokens</p>
                      <h6>
                        <span>Note: </span>This message is from admin side
                      </h6>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {(pool?.poolStatus === undefined || pool?.poolStatus === false) && pool?.poolOwner !== address?.toLocaleLowerCase() ? (
              <>
                <KYCMessagePage />
              </>
            ) : (
              <>
                <Container className='position-relative'>
                  <Row className='mb-5'>
                    <div className='col-lg-4 mb-lg-0 mb-3'>
                      <div className='profile-info position-relative pools-position'>
                        {pool?.scamStatus === true ? (
                          <>
                            <div className='profile-info-scam-text d-flex align-items-center'>
                              <div className='profile-info-scam-text-img'>
                                <Image src={scamAlert} className='img-fluid' />
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className='top-sec '>
                          <div className='profile'>
                            <img src={`https://ipfs.io/ipfs/${pool?.logo}`} alt='Profile img' onError={DefualtImageSetter} />
                          </div>
                          <div className='name'>
                            <h3>{pool?.title}</h3>
                            <div className='text-sm font-secondary text-ignored'>
                              {pool?.ptSymbol} / {pool?.bdSymbol}
                            </div>
                          </div>
                        </div>
                        <div className='badges'>
                          {!poolStarted && (parseInt(pool?.poolStartDate)+ offsetInSeconds) > moment(Date.now()).unix() ? <span className='outerside soon'>upcoming</span> : ''}
                          <> </>
                          {(parseInt(pool?.poolStartDate)+offsetInSeconds) < moment(Date.now()).unix() && parseInt(pool?.poolEndDate) > moment(Date.now()).unix() && pool?.closeStatus !== true ? (
                            <span className='outerside level live-badge'>Live</span>
                          ) : (
                            ''
                          )}
                          {pool?.closeStatus === true || parseInt(pool?.poolEndDate) < moment(Date.now()).unix() ? <span className='outerside close-badge soon'>Closed</span> : ''}
                          {pool?.isKyc === true ? <span className='outerside badge-light-green kyc'>KYC</span> : <></>}{' '}
                        </div>
                        {address !== '' && address !== undefined ? (
                          <div className='wallet-sec text-center'>
                            {!poolStarted && (parseInt(pool?.poolStartDate) + offsetInSeconds) > moment(Date.now()).unix() ? (
                              <>
                              {
                                (pool?.poolOwner === address.toLowerCase()) && (pool?.poolStatus === false ||pool?.poolStatus === undefined) ?
                                <b className='mt-3' style={{color:"yellow"}}>KYC approval is awaiting from admin </b>
                                :
                                <h4 className='text-white mt-3'>
                                Sale Starts on
                                <IDOClock date1={moment.unix(pool?.poolStartDate)} poolStarted={() => setPoolStarted(true)} />
                              </h4>
                              }
                               
                              </>
                            ) : parseInt(pool?.poolEndDate) <= moment(Date.now()).unix() && pool?.closeStatus === false && pool?.scamStatus === false ? (
                              <>
                              {
                                (pool?.poolOwner === address.toLowerCase()) && (pool?.poolStatus === false ||pool?.poolStatus === undefined) ?
                                <b className='mt-3' style={{color:"yellow"}}>KYC approval is awaiting from admin </b>
                                :
                                <Button className='btn light-blue-btn my-0' onClick={closeClearPool} disabled={pool?.poolOwner === address?.toLocaleLowerCase() ? false : true}>
                                  {' '}
                                  Settle Pool
                                </Button>
                              }
                                
                              </>
                            ) : pool?.closeStatus === false && pool?.scamStatus === true ? (
                              <>
                              {
                                (pool?.poolOwner === address.toLowerCase()) && (pool?.poolStatus === false ||pool?.poolStatus === undefined) ?
                                <b className='mt-3' style={{color:"yellow"}}>KYC approval is awaiting from admin </b>
                                :
                                <h4 className='text-white mt-2'>Pool Is Scammed</h4>
                              }
                              </>
                            ) : parseInt(pool?.poolEndDate) < moment(Date.now()).unix() && pool?.closeStatus === true ? (
                              <>
                              {
                                (pool?.poolOwner === address.toLowerCase()) && (pool?.poolStatus === false ||pool?.poolStatus === undefined) ?
                                <b className='mt-3' style={{color:"yellow"}}>KYC approval is awaiting from admin </b>
                                :
                                <h4 className='text-white mt-2'>Sale Ended</h4>
                              }
                              </>
                            ) : (
                              <>
                                {whiteListAddresses?.poolType === 'whiteList' &&
                                 (whiteListAddresses?.whiteListUsers?.includes(address?.toString()) !== true ||
                                  whiteListAddresses?.whiteListUsers === '' ||
                                  whiteListAddresses?.whiteListUsers === undefined) ? (
                                  <>
                                  {
                                    (pool?.poolOwner === address.toLowerCase()) && (pool?.poolStatus === false ||pool?.poolStatus === undefined) ?
                                    <b className='mt-3' style={{color:"yellow"}}>KYC approval is awaiting from admin </b>
                                    :
                                    <h5 className='text-white mt-2'>You are not in WhiteList</h5>
                                  }
                                  </>
                                  //1682668260
                                ) : (
                                  <>
                                    <div className='input-wrapper justify-content-center'>
                                      {approveErr === '' ? (
                                        <>
                                          <div className='slide_text_code product-info-content-wrapper'>
                                            <div className='d-flex disp'>
                                              <div className='filed-wrapper stake-wrap mb-2'>
                                                <input
                                                  type='number'
                                                  value={poolOrder?.buyAmount}
                                                  onWheel={event => {
                                                    event.preventDefault();
                                                  }}
                                                  placeholder={`Buy ` + pool?.ptName}
                                                  name='stake-amou'
                                                  className='form-control input-field text-white'
                                                  onChange={handlechangeBuy}
                                                  disabled={pool?.poolOwner === address?.toLocaleLowerCase() ? true : false}
                                                  required
                                                />
                                                {pool?.poolOwner !== address?.toLocaleLowerCase() ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        float: 'left',
                                                        color: 'yellow',
                                                      }}
                                                    >
                                                      min buy: {pool?.minimumBuyAmount} {pool?.ptName.substring(0, 5)}
                                                    </span>
                                                    <span
                                                      style={{
                                                        float: 'right',
                                                        color: 'yellow',
                                                      }}
                                                    >
                                                      max buy: {pool?.poolSellAmount} {pool?.ptName.substring(0, 5)}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <> </>
                                                )}
                                              </div>
                                              {buyOrderErr ? (
                                                <>
                                                  {/* <p className='text-white text-center float-center' style={{ color: 'yellowgreen' }}>
                                                    <img src='https://i.stack.imgur.com/qq8AE.gif' alt='loader' style={{ width: '7%' }} />
                                                  </p> */}
                                                  <div className='text-danger p-1'>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                    <span className='ml-2 ms-2'>{buyOrderErr}</span>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <div className='filed-wrapper stake-wrap mb-2'>
                                                <input
                                                  type='number'
                                                  value={poolOrder?.sellAmount}
                                                  ref={inputRef}
                                                  onWheel={onWheel}
                                                  placeholder={`Bid Your's ` + pool?.bdSymbol}
                                                  name='stake-amou'
                                                  className='form-control input-field text-white'
                                                  onChange={e => {
                                                    handlechangeSell(e);
                                                  }}
                                                  disabled={pool?.poolOwner === address?.toLocaleLowerCase() ? true : false}
                                                  required
                                                />
                                                {pool?.poolOwner !== address?.toLocaleLowerCase() ? (
                                                  <>
                                                    <span
                                                      style={{
                                                        float: 'left',
                                                        color: 'yellow',
                                                      }}
                                                    >
                                                      {orders.length > 0 ? <> last bid amount: </> : <>min bid/sell: </>}
                                                      {orders.length > 0 ? orders[orders.length - 1]?.sellAmounts : pool?.minimumBiddingAmount} {pool?.bdSymbol}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              {bidSellErr ? (
                                                <>
                                                  {/* <p className='text-white text-center float-center' style={{ color: 'yellowgreen' }}>
                                                    <img src='https://i.stack.imgur.com/qq8AE.gif' alt='loader' style={{ width: '7%' }} />
                                                  </p> */}
                                                  <div className='text-danger p-1'>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                    <span className='ml-2 ms-2'>{bidSellErr}</span>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {errors === '' && bidSellErr === '' && orderBuyLoader === false && orderSellLoader === false && address?.toLocaleLowerCase() !== pool?.poolOwner ? (
                                                <div>
                                                  <button
                                                    className='btn light-blue-btn my-0'
                                                    onClick={submitPoolOrder}
                                                    disabled={
                                                      poolOrder?.buyAmount !== '' && poolOrder?.buyAmount !== undefined && (poolOrder?.sellAmount !== '') & (poolOrder?.sellAmount !== undefined)
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    Order
                                                  </button>
                                                </div>
                                              ) : (
                                                <button className='btn light-blue-btn my-0' disabled={true}>
                                                  Order
                                                </button>
                                              )}
                                              {address?.toLocaleLowerCase() === pool?.poolOwner ? (
                                                <>
                                                  <span style={{ color: 'yellow', float: 'left' }}>Owner can not place an order</span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className='slide_text w-100'>
                                              <span className='d-flex'>
                                                <span style={{ color: 'yellow' }}> Calculated Bid Ratio:</span>{' '}
                                                <b>
                                                  {orders?.length > 0
                                                    ? (Number(orders[orders?.length - 1].sellAmounts) / Number(orders[orders?.length - 1].minBuyAmounts)).toFixed(2)
                                                    : (Number(pool?.minimumBiddingAmount) / Number(pool?.minimumBuyAmount)).toFixed(2)}
                                                </b>
                                              </span>
                                              <span className='d-flex'>
                                                <span style={{ color: 'yellow' }}> Your Bid Ratio:</span>{' '}
                                                <b>
                                                  {poolOrder?.buyAmount !== '' && poolOrder?.buyAmount !== undefined && poolOrder?.sellAmount !== '' && poolOrder?.sellAmount !== undefined
                                                    ? (Number(poolOrder?.sellAmount) / Number(poolOrder?.buyAmount)).toFixed(2)
                                                    : '0'}
                                                </b>
                                              </span>
                                              <span>
                                                <a
                                                  href='#'
                                                  onClick={() => {
                                                    Swal.fire('Bid Ratio Formula', `Ratio = Bid / Buy <br /> bid=2; buy=7; <br /> bid/buy=2/7 <br />  = 0.334`, 'question');
                                                  }}
                                                >
                                                  Click to View Bid Ratio Formula
                                                </a>
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className='slide_text_code'>
                                            <div className='d-flex'>
                                              <div className='filed-wrapper stake-wrap mb-2'>
                                                <input type='number' value={''} placeholder='Approve Your Tokens' name='approveAmou' disabled={true} className='input-field text-white' required />
                                              </div>
                                              <button className='light-blue-btn my-0' onClick={handleApprove}>
                                                Approve
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {errors !== '' ? (
                              <div className='text-danger p-1'>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span className='ml-2 ms-2'>{errors}</span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : pool?.poolStatus === false && address === process.env.REACT_APP_OWNER_ADDRESS ?
                        (
                          <small>KYC approval is awaiting from admin</small>
                        )
                        :
                        (
                          <>
                            <div className='wallet-sec text-center'>
                              <div className='d-flex justify-content-center'>
                                <ConnectButton />
                              </div>
                            </div>
                          </>
                        )}
                        {pool?.closeStatus === false ? (
                          <>
                            <a href='https://docs.google.com/spreadsheets/d/1lr_wHVWkNlMNL4HxjAyUHE13li64JBktKR2VtWitzSY/edit?usp=sharing'>
                              <span style={{ color: 'grey', float: 'right' }}> MutoPad Sale Calculator </span>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className='usd'>
                          <h3 className='mt-4'>
                            1 {pool?.ptName} = {(pool?.minimumBiddingAmount / pool?.minimumBuyAmount)?.toFixed(2)} {pool?.bdSymbol}
                          </h3>
                          <h4 className='mt-1'>
                            1 {pool?.bdSymbol} = {(pool?.minimumBuyAmount / pool?.minimumBiddingAmount)?.toFixed(2)} {pool?.ptName}
                          </h4>
                        </div>
                        <div className='progress-bar'>
                          <div className='prog-text d-flex'>
                            <span style={{ color: 'white' }}>
                              {' '}
                              🏃 <> </>
                              Close in {remainTime.day} Days, {remainTime.hours} hours
                            </span>
                          </div>
                        </div>
                        <div className='progress-bar'>
                          <div className='prog-text d-flex'>
                            <span style={{ color: 'white' }}>Progress</span>
                            <span style={{ color: 'white' }}>{orders?.length > 0 ? orders?.length : 0} Bids</span>
                          </div>
                          <ProgressBar now={orders?.length > 0 ? (Number(orders[orders?.length - 1]?.thresholdPercentage) > 100 ? 100 : orders[orders?.length - 1]?.thresholdPercentage) : 0} />
                          <div className='prog-text d-flex '>
                            <span style={{ color: 'white' }}>{orders?.length > 0 ? Number(orders[orders?.length - 1]?.thresholdPercentage) : 0}%</span>
                            <span style={{ color: 'white' }}>
                              {orders?.length > 0 ? orders[orders?.length - 1]?.thresholdCounter : 0} <span>/</span> {pool?.minimumFundingThreshold}
                            </span>
                          </div>
                        </div>
                        <div className='wifi text-white'>
                          <svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 640 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98zM320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm202.67-83.59c-115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z'></path>
                          </svg>
                          <span>IDO and distribution on BSC</span>
                        </div>
                        <div className='d-sm-flex text-white justify-content-between '>
                          <div className='sale'>
                            💰 <> </>
                            <span className='me-4'>Sale</span>
                            <span>{moment.unix(pool?.poolStartDate).format('DD MMMM YYYY, HH:mm a')}</span>
                          </div>
                        </div>
                        {item.poolStatus === 'close' && address?.toLocaleLowerCase() !== undefined ? (
                          <OrderDetail idoAddress={item.idoAddress} ownerAddress={address?.toLocaleLowerCase()} bidDecimal={item.biddingToken} poolDecimal={item.decimals} />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* here Start product infp */}
                    <div className='col-lg-8 mb-lg-0 mb-3'>
                      <div className='coin-detail text-white position-relative'>
                        {pool?.scamStatus === true ? (
                          <>
                            <div className='profile-info-scam-text local-pool d-flex align-items-center justify-content-center'>
                              <div className='profile-info-scam-text-content'>
                                <div className='d-flex align-items-center justify-content-between mb-4'>
                                  <h5>Disclaimer Notice</h5>

                                  <div className='scamsmall-img'>
                                    <Image src={scamAlertsmall} className='img-fluid' />
                                  </div>
                                </div>
                                <p>
                                  Attention all users: It has come to our attention that a pool associated with our organization may have been created by scammers. Please be aware that this pool is
                                  not legitimate and we advise you not to send any funds to it. We are investigating the matter and will provide updates as soon as possible. Thank you for your
                                  understanding and cooperation.
                                </p>
                                <h6>
                                  <span>Note: </span>This message is from admin side
                                </h6>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className='d-flex flex-sm-row flex-column align-items-center justify-content-between mb-3'>
                          <div className='coin-profile coin-profile-text d-flex align-items-center'>
                            <div className='coin-profile-img'>
                              <img className='me-2' src={`https://ipfs.io/ipfs/${pool?.logo}`} alt='Profile img' onError={DefualtImageSetter} />
                            </div>
                            <h3 className='mb-0 ms-1'>{pool?.title}</h3>
                          </div>
                          {address !== '' && address !== undefined ? (
                            <>
                              {pool?.network === 'BSC Testnet' ? (
                                <>
                                  <div className='mb-sm-0 mb-2 pools-box-logo'>
                                    {' '}
                                    <img src='https://dex-bin.bnbstatic.com/static/images/logo_BNB_Chain.svg' className='chainLogo mt-10' alt='network logo'></img>
                                  </div>
                                </>
                              ) : pool?.network === 'Goerli Testnet' ? (
                                <>
                                  <div className='mb-sm-0 mb-2 pools-box-logo'>
                                    {' '}
                                    <img src='https://trustpad.io/_next/static/media/ethereum-logo-full.1d48af6f.png' className='chainLogo mt-10' alt='network logo'></img>
                                  </div>
                                </>
                              ) : (
                                <> </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className='social-icons'>
                          <ul className='mb-0'>
                            {pool?.telegram !== '' && (
                              <li>
                                <a href={pool?.telegram ? pool?.telegram : ''} target='_blank' rel='noopener noreferrer'>
                                  <svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z'></path>
                                  </svg>
                                </a>
                              </li>
                            )}
                            {pool?.medium !== '' && (
                              <li>
                                <a href={pool?.medium ? pool?.medium : ''} target='_blank' rel='noopener noreferrer'>
                                  <svg stroke='currentColor' fill='currentColor' viewBox='0 0 50 50' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M45,4H5C4.448,4,4,4.448,4,5v40c0,0.552,0.448,1,1,1h40c0.552,0,1-0.448,1-1V5C46,4.448,45.552,4,45,4z M40,13.5 l-1.821,2.197C38.064,15.811,38,15.965,38,16.125V32.75c0,0.16,0.064,0.314,0.179,0.428L40,35.546V36H30v-0.454l2.821-2.368 C32.936,33.064,33,32.91,33,32.75V17.879L24.848,36h-0.001h-1.543l0,0L15,18.375v13.108c0,0.22,0.076,0.433,0.215,0.605L18,35.546 V36h-8v-0.454l2.783-3.438C12.923,31.936,13,31.721,13,31.5V16.388c0-0.142-0.05-0.279-0.142-0.388L11,13.5V13h7.097l7.624,16.183 L33.002,13H40V13.5z' />
                                  </svg>
                                </a>
                              </li>
                            )}
                            {pool?.twitter !== '' && (
                              <li>
                                <a href={pool?.twitter ? pool?.twitter : ''} target='_blank' rel='noopener noreferrer'>
                                  <svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path>
                                  </svg>
                                </a>
                              </li>
                            )}
                            {pool?.podcast !== '' && (
                              <li>
                                <a href={pool?.podcast ? pool?.podcast : ''} target='_blank' rel='noopener noreferrer'>
                                  <svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 384 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M192 352c53.03 0 96-42.97 96-96h-80C199.2 256 192 248.8 192 240S199.2 224 208 224H288V192h-80C199.2 192 192 184.8 192 176S199.2 160 208 160H288V127.1h-80c-8.836 0-16-7.164-16-16s7.164-16 16-16L288 96c0-53.03-42.97-96-96-96s-96 42.97-96 96v160C96 309 138.1 352 192 352zM344 192C330.7 192 320 202.7 320 215.1V256c0 73.33-61.97 132.4-136.3 127.7c-66.08-4.169-119.7-66.59-119.7-132.8L64 215.1C64 202.7 53.25 192 40 192S16 202.7 16 215.1v32.15c0 89.66 63.97 169.6 152 181.7V464H128c-18.19 0-32.84 15.18-31.96 33.57C96.43 505.8 103.8 512 112 512h160c8.222 0 15.57-6.216 15.96-14.43C288.8 479.2 274.2 464 256 464h-40v-33.77C301.7 418.5 368 344.9 368 256V215.1C368 202.7 357.3 192 344 192z' />
                                  </svg>
                                </a>
                              </li>
                            )}
                            {pool?.website !== '' && (
                              <li className='mx-0'>
                                <a href={pool?.website ? pool?.website : ''} target='_blank' rel='noopener noreferrer'>
                                  <svg
                                    stroke='currentColor'
                                    fill='currentColor'
                                    strokeWidth='0'
                                    height='1em'
                                    width='1em'
                                    enableBackground='new 0 0 500 500'
                                    id='Layer_1'
                                    version='1.1'
                                    viewBox='0 0 500 500'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      clipRule='evenodd'
                                      d='M31.949,250c0,120.473,97.58,218.051,218.051,218.051  c120.473,0,218.051-97.578,218.051-218.051c0-120.471-97.578-218.051-218.051-218.051C129.529,31.949,31.949,129.529,31.949,250z   M77.375,250c0-13.992,1.638-27.62,4.814-40.61L122.802,250l-9.084,54.513l36.342,36.34v49.883  C106.088,359.48,77.375,308.063,77.375,250z M210.295,82.007c12.72-3.087,25.988-4.631,39.705-4.631c15.173,0,29.891,2,43.883,5.634  l-7.542,12.539l9.086,9.084h27.258l7.543-7.541c6.35,3.362,12.35,6.996,18.169,11.084l-16.625,5.542l-18.173,18.17l18.173,9.086  v9.086h-18.173v18.17c0,0,12.536,9.086,27.254,9.086c14.448,0,7.998-19.897,18.174-27.256c10.267-7.361,22.715-5.724,22.715-5.724  l5.906,1.544c11.085,14.626,19.892,30.984,25.891,48.697v0.909c0,0-12.812-9.086-36.34-9.086c-23.53,0-45.427,9.086-45.427,9.086  s-25.627,7.453-27.259,27.257c-2.82,34.069,9.086,45.428,9.086,45.428l45.428,27.255v88.408  C329.322,408.09,291.339,422.624,250,422.624c-19.532,0-38.249-3.174-55.785-9.174l46.699-108.938l-9.084-45.427  c0,0-36.071-45.428-54.513-45.428c-18.441,0-27.256,27.256-27.256,27.256l-18.172-36.34l54.512-36.343l27.257-72.683L210.295,82.007  z'
                                      fillRule='evenodd'
                                    />
                                  </svg>
                                </a>
                              </li>
                            )}
                            {/* {pool?.whitePaper !== '' && (
                              <li className='mx-0'>
                                <a href={pool?.whitePaper ? pool?.whitePaper : ''} target='_blank' rel='noopener noreferrer'>
                                  <svg
                                    stroke='currentColor'
                                    fill='currentColor'
                                    strokeWidth='0'
                                    height='1em'
                                    width='1em'
                                    enableBackground='new 0 0 500 500'
                                    id='Layer_1'
                                    version='1.1'
                                    viewBox='0 0 500 500'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      clipRule='evenodd'
                                      d='M51.748,55.789H12.252c-1.418,0-2.567-1.149-2.567-2.567V10.779c0-1.418,1.149-2.567,2.567-2.567    h23.555c0.682,0,1.335,0.271,1.817,0.754l15.941,15.972c0.48,0.481,0.75,1.133,0.75,1.814v26.471    C54.315,54.639,53.166,55.789,51.748,55.789z M14.819,50.654h34.361V27.812L34.742,13.346H14.819V50.654z'
                                      fillRule='evenodd'
                                    />
                                  </svg>
                                </a>
                              </li>
                            )} */}
                          </ul>
                        </div>
                        <div className='pool-detail'>
                          <h4>Pool Details</h4>
                          <ul>
                            <li>
                              <span className='title'>Name: </span>
                              <span className='desc-color'>{pool?.title}</span>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span className='title'>Description: </span>
                              <span className='desc-color'>
                                <div dangerouslySetInnerHTML={htmlFormater(pool?.description)} />
                              </span>
                              {/* <span className='desc-color'>{{_html:pool?.description}}</span> */}
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span className='title'>Swap Rate: </span>
                              <span className='desc-color'>
                                1 {pool?.ptName}
                                <span className='text-white'> = </span> ${(pool?.minimumBiddingAmount / pool?.minimumBuyAmount)?.toFixed(2)} |{' '}
                                {(pool?.minimumBiddingAmount / pool?.minimumBuyAmount)?.toFixed(2)} {pool?.bdSymbol}
                                <span className='text-white'> per </span> 1 {pool?.ptSymbol}
                              </span>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span className='title'>Start Date: </span>
                              <span>{moment.unix(pool?.poolStartDate).format('DD MMMM YYYY, HH:mm a')} UTC</span>
                            </li>
                            <li>
                              <span className='title'>End Date: </span>
                              <span>{moment.unix(pool?.poolEndDate).format('DD MMMM YYYY, HH:mm a')} UTC</span>
                            </li>
                          </ul>
                          <h4>Token Details</h4>
                          <ul>
                            <li>
                              <span className='title'>Token Name: </span>
                              <span className='desc-color'>{pool?.ptName}</span>
                            </li>
                            <li>
                              <span className='title'>Token Decimals: </span>
                              <span className='desc-color'>{pool?.ptDecimals}</span>
                            </li>
                            <li>
                              <span className='title'>Token Symbol: </span>
                              <span className='desc-color'>{pool?.ptSymbol}</span>
                            </li>
                            <li>
                              <span className='title'>Token Address: </span>
                              <span className='desc-color'>{pool?.poolingToken}</span>
                            </li>
                            <li></li>
                            <li className='mb-0'>
                              <span className='title'>Token Listing: </span>
                              <span className='title'>TBA: </span>
                              {moment.unix(pool?.poolEndDate).format('DD MMMM YYYY, HH:mm a')} UTC
                            </li>
                          </ul>
                          <h4>Soft Cap || Hard Cap</h4>
                          <ul className='mb-0'>
                            <li>
                              <span className='title'>Soft Cap: </span>
                              <span>
                                {pool?.minimumFundingThreshold} {pool?.bdSymbol}
                              </span>
                            </li>
                            <li>
                              <span className='title'>Hard Cap: </span>
                              <span>
                                {pool?.poolSellAmount} {pool?.ptSymbol}
                              </span>
                            </li>
                          </ul>
                          <h4>Distribution</h4>
                          <ul className='mb-0'>
                            <li>
                              <span className='title'>Distribution: </span>
                              <span> Claimed on MutoPad</span>
                            </li>
                          </ul>
                          <h4>Threshold</h4>
                          <ul className='mb-0'>
                            <li>
                              <span className='title'>Minimum Funding Threshold: </span>
                              <span>
                                {pool?.minimumFundingThreshold} {pool?.bdSymbol}
                              </span>
                            </li>
                          </ul>
                          <h4>Threshold Status</h4>
                          <ul className='mb-0'>
                            <li>
                              <div className='d-sm-flex'>
                                <span className='title mb-lg-0 mb-md-0 mb-2 d-block me-3'>Threshold Achieved/Not Achieved: </span>
                                <span className='badges' style={{ margin: '0' }}>
                                  {parseInt(pool?.poolEndDate) < moment(Date.now()).unix() ? (
                                    <>
                                      {orders[orders?.length - 1]?.thresholdStatus === true ? (
                                        <>
                                          <span className='outerside level'> Achieved </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className='outerside soon' s>
                                            {' '}
                                            Not Achieved{' '}
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <span className='outerside kyc'> Pool is not yet Ended/Closed </span>
                                  )}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {loading ? <ModalLoading /> : <></>}
                  </Row>
                  <Row>
                    <div className='col-lg-12 mb-3'>
                      <div className='pool-table'>
                        <div className='table-responsive manage-pools mx-0 px-0'>
                          <Table>
                            <thead>
                              <tr className='text-center'>
                                <th>Order</th>
                                <th className='text-center' style={{ textAlign: 'left', paddingLeft: '19px !important' }}>
                                  Bids Info
                                </th>
                                {pool?.closeStatus === true ? (
                                  <th className='text-center' style={{ textAlign: 'left', paddingLeft: '19px !important' }}>
                                    Distribution Info
                                  </th>
                                ) : (
                                  ''
                                )}
                                <th className='text-center' style={{ textAlign: 'left', paddingLeft: '19px !important' }}>
                                  Status
                                </th>
                                <th>Transaction Details</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems && currentItems?.length > 0 ? (
                                currentItems?.map((item, index) => {
                                  return (
                                    <tr key={index} className='text-center'>
                                      <td>
                                        {item?.network === 'BSC Testnet' ? (
                                          <a href={`https://testnet.bscscan.com/tx/${item.txHash}`} style={{ color: 'white' }}>
                                            <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} /> 0x...
                                            {item.transactionHash?.substring(60, 63)}
                                          </a>
                                        ) : (
                                          <a href={`https://goerli.etherscan.io/tx/${item.txHash}`} style={{ color: 'white' }}>
                                            <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} /> 0x...
                                            {item.transactionHash?.substring(60, 63)}
                                          </a>
                                        )}
                                        <br />
                                        <small>🤷 0x..{item.orderBy?.substring(39, 42)}</small>
                                      </td>
                                      <td align='center'>
                                        {item?.claimStatus === false ? (
                                          <>
                                            <small>
                                              <b>💰 Demand:{item.minBuyAmounts + ' ' + pool?.ptSymbol}</b>
                                            </small>
                                            <br />
                                            <small>
                                              <b>💵 Bid :{item.sellAmounts + ' ' + pool.bdSymbol}</b>
                                            </small>
                                          </>
                                        ) : (
                                          <>
                                            <small>
                                              <b>💰 Demanded:{item.minBuyAmounts + ' ' + pool?.ptSymbol}</b>
                                            </small>
                                            <br />
                                            <small>
                                              <b>💵 Bid :{item.sellAmounts + ' ' + pool.bdSymbol}</b>
                                            </small>
                                          </>
                                        )}
                                      </td>
                                      {
                                        pool?.closeStatus === true ?
                                        <>
                                         {orders && item?.claimStatus === true ? (
                                          <td align='center'>
                                            <small>
                                              <b>
                                                {/* {pool?.closeStatus === true 
                                          ? '💰 Claimable: ' +
                                            gFindClearingPrice(
                                              [{ sellAmount: toWeiValue(item.sellAmounts, item.bdDecimals), buyAmount: toWeiValue(item.minBuyAmounts, item.ptDecimals) }],
                                              gDecodeOrder(clearingPriceOrder),
                                              item.bdDecimals,
                                            ) +
                                            ' ' +
                                            pool?.ptSymbol
                                          : '💰 Demand on bid: ' + item?.thresholdStatus !==true ? <del> {item.minBuyAmounts} + ' ' + {pool?.ptSymbol}+ </del> : item.minBuyAmounts + ' ' + pool?.ptSymbol
                                        } */}
                                                <>
                                                  {item?.trxStats?.claimedType === 'claimed&refund' ? (
                                                    <>
                                                      💰 Claimed: {item?.trxStats?.claimedValue} {pool?.ptSymbol} <br />
                                                      💵 Refunded: {item?.trxStats?.refundedValue} {pool?.bdSymbol}
                                                    </>
                                                  ) : item?.trxStats?.claimedType === 'claimed' ? (
                                                    <>
                                                      💰 Claimed: {item?.trxStats?.claimedValue} {pool?.ptSymbol}{' '}
                                                    </>
                                                  ) : item?.trxStats.claimedType === 'refunded' ? (
                                                    <>
                                                      💵 Refunded: {item?.trxStats?.refundedValue} {pool?.bdSymbol}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              </b>
                                            </small>
                                          </td>
                                        ) : (
                                          <td align='center'>
                                            {' '}
                                            <small>--</small>
                                          </td>
                                        )}
                                        </> 
                                        :
                                        <></>
                                      }
                                     
                                      <td>
                                        {item?.claimStatus === true ? (
                                          <>
                                            <div className='badges' style={{ margin: '0' }}>
                                              <span className='outerside level'>Completed</span>
                                            </div>
                                          </>
                                        ) : item?.claimStatus === false ? (
                                          <>
                                            <div className='badges' style={{ margin: '0' }}>
                                              <span className='outerside kyc'>Pending</span>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td align='center'>
                                        {item?.claimStatus !== true ? (
                                          <small>Not claim/refund yet</small>
                                        ) : (
                                          <>
                                            <small>
                                              {item?.claimStatus === true && item?.network === 'BSC Testnet' ? (
                                                <a href={`https://testnet.bscscan.com/tx/${item.claimTrxHash}`} target='blank' style={{ color: 'white' }}>
                                                  <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} /> 0x...
                                                  {item?.claimTrxHash?.substring(60, 63)}
                                                </a>
                                              ) : item?.claimStatus === true && item?.network === 'Goerli Testnet' ? (
                                                <a href={`https://goerli.etherscan.io/tx/${item?.claimTrxHash}`} target='blank' style={{ color: 'white' }}>
                                                  <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} /> 0x...
                                                  {item?.claimTrxHash?.substring(60, 63)}
                                                </a>
                                              ) : (
                                                <></>
                                              )}
                                            </small>
                                            <br />
                                            <small>{moment(item.updatedAt).format('llll')}</small>
                                          </>
                                        )}
                                      </td>
                                      <td>
                                        {(pool?.scamStatus === true || pool?.isCancelled === true) && address?.toLocaleLowerCase() === item?.orderBy && item?.claimStatus === false ? (
                                          <>
                                            <button className='btn btn-success btn-sm' onClick={e => submitClaim(e, index, item?.blockchainPoolId, item?.userId, false)}>
                                              <small> Refund </small>
                                            </button>
                                          </>
                                        ) : pool?.closeStatus === false && parseInt(pool?.poolEndDate) - moment(Date.now()).unix() < 89 ? (
                                          <>
                                            <small>Waiting to settle</small>
                                          </>
                                        ) : orders[orders.length - 1]?.thresholdStatus === true ? (
                                          <>
                                            {item?.claimStatus === false && address?.toLocaleLowerCase() === item?.orderBy && pool?.closeStatus === true ? (
                                              <button className='btn btn-success btn-sm' onClick={e => submitClaim(e, index, item?.blockchainPoolId, item?.userId, true)}>
                                                <small> Claim </small>
                                              </button>
                                            ) : item?.claimStatus === true && item?.trxStats?.claimedType === 'claimed' ? (
                                              <button className='btn btn-danger btn-sm' disabled>
                                                <small> Claimed </small>
                                              </button>
                                            ) : item?.claimStatus === true && item?.trxStats?.claimedType === 'claimed&refund' ? (
                                              <button className='btn btn-danger btn-sm' disabled>
                                                <small>Claimed and Refunded</small>
                                              </button>
                                            ) : item?.claimStatus === true && item?.trxStats?.claimedType === 'refunded' ? (
                                              <button className='btn btn-danger btn-sm' disabled>
                                                <small>Refunded</small>
                                              </button>
                                            ) : (
                                              <>{address?.toLocaleLowerCase() !== item?.orderBy ? <span> --</span> : ''}</>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {item?.claimStatus === false && address?.toLocaleLowerCase() === item?.orderBy && pool?.closeStatus === true ? (
                                              <button className='btn btn-success btn-sm' onClick={e => submitClaim(e, index, item?.blockchainPoolId, item?.userId, false)}>
                                                <small> Refund </small>
                                              </button>
                                            ) : item?.claimStatus === true ? (
                                              <button className='btn btn-danger btn-sm' disabled>
                                                <small> Refunded </small>
                                              </button>
                                            ) : (
                                              <>{address?.toLocaleLowerCase() !== item?.orderBy ? <span> --</span> : ''}</>
                                            )}
                                          </>
                                        )}
                                        {item?.claimStatus !== true &&
                                        pool?.closeStatus === false &&
                                        pool?.isCancelled === false &&
                                        item?.orderBy === address?.toLocaleLowerCase() &&
                                        parseInt(pool?.poolEndDate) - moment(Date.now()).unix() > 90 ? (
                                          <>
                                            <button className='btn btn-warning btn-sm' onClick={e => emergencyWithdraw(e, index, pool?.poolId, item?.userId, item?.minBuyAmounts, item?.sellAmounts)}>
                                              <small>Emergency Withdraw</small>
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <td colspan='5' className='text-center'>
                                  <span className=' text-light'> No record found</span>
                                </td>
                              )}
                            </tbody>
                          </Table>
                        </div>
                        {orders && orders?.length > 4 ? (
                          <>
                            <ReactPaginate
                              nextLabel='next >'
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              pageCount={pageCount}
                              previousLabel='< previous'
                              pageClassName='page-item'
                              pageLinkClassName='page-link'
                              previousClassName='page-item'
                              previousLinkClassName='page-link'
                              nextClassName='page-item'
                              nextLinkClassName='page-link'
                              breakLabel='...'
                              breakClassName='page-item'
                              breakLinkClassName='page-link'
                              containerClassName='pagination'
                              activeClassName='active'
                              renderOnZeroPageCount={null}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className='comment-section mt-3'>
                        <div className='comments'>
                          <Form className='add-comment d-flex align-items-center justify-content-between'>
                            <div className='comments-content d-flex align-items-center'>
                              <div className='comments-content-logo'>
                                <Image src={smallLogo} className='img-fluid' />
                              </div>
                              <textarea
                                placeholder='Comments ... '
                                value={commentMsg}
                                onChange={e => {
                                  setCommentMsg(e.target.value);
                                }}
                              ></textarea>
                            </div>
                            <div className='text-end'>
                              <Button className='btn-blue' variant='success' onClick={() =>{
                                if(address === undefined || address === ''){
                                  Swal.fire('Sorry!','please connect your wallet to communicate', 'warning');
                                } else {
                                  handlePostComment();
                                }
                              }}>
                                Add Comment
                              </Button>
                            </div>
                          </Form>

                          {commentError ? (
                            <>
                              <span className='text-danger ms-5 ps-3'>
                                <>
                                  {' '}
                                  <FontAwesomeIcon icon={faInfoCircle} />{' '}
                                </>
                                {commentError}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <hr style={{ color: 'white' }} />
                          <div className='comments-content-wrapper'>
                            {poolsComments?.length > 0 &&
                              poolsComments.map((item, index) => (
                                <div className='comment' key={index}>
                                  <div className='user mb-2'>
                                    <div className='user-details d-flex mb-2'>
                                      <span className='me-2'> 🤷 </span> <p className='mb-0 username'>0x...{item.userAddress?.substring(39, 42)}</p>
                                    </div>
                                    <div className='upload-time d-flex'>
                                      <span className='me-2'>⏰</span>
                                      <p className='mb-0'>{item?.messageTime ? moment.unix(item?.messageTime).format('DD MMMM YYYY, HH:mm a') : moment(item.createdAt).format('YY-MM-DD HH:MM')}</p>
                                    </div>
                                  </div>
                                  <div className='comment-details d-flex'>
                                    <span className='me-2'> 📑 </span>
                                    <p>{item.message}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading ? <ModalLoading /> : <></>}
                  </Row>
                </Container>
                {modalShow ? (
                  <Modal className='pool-modal auction-modal' show={modalShow} backdrop='static' keyboard={false} onHide={() => setModalShow(false)} centered>
                    <Modal.Header closeButton>
                      <Modal.Title id='contained-modal-title-vcenter'>Place Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {modalSuccess === true ? (
                        <div className='text-center modal-success-status'>
                          <div>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </div>
                          <div>Order is Placed Successfully</div>
                          <div></div>
                        </div>
                      ) : (
                        <Accordion>
                          <Accordion.Item eventKey='0'>
                            <Accordion.Header>
                              <div className={apTok1 ? 'icon' : 'icon pending'}>
                                <FontAwesomeIcon icon={apTok1 ? faCheck : faExclamation} />
                                {apTok1 ? (
                                  ''
                                ) : (
                                  <div className='loader'>
                                    <div className='lds-ring'>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {bidBalErr === '' ? <>Approve "{pool?.title}" allowance tokens</> : <p className='text-danger p-2'>{bidBalErr}</p>}
                            </Accordion.Header>
                          </Accordion.Item>
                          <Accordion.Item eventKey='1'>
                            <Accordion.Header>
                              <div className={apTok2 ? 'icon' : 'icon pending'}>
                                <FontAwesomeIcon icon={apTok2 ? faCheck : faExclamation} />
                                {apTok2 ? (
                                  ''
                                ) : (
                                  <div className='loader'>
                                    <div className='lds-ring'>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {poolBalErr === '' ? <>{'Place Order'}</> : <p className='text-danger p-2'>{poolBalErr}</p>}
                            </Accordion.Header>
                          </Accordion.Item>
                        </Accordion>
                      )}
                    </Modal.Body>
                    <Modal.Footer className='text-center'></Modal.Footer>
                  </Modal>
                ) : (
                  ''
                )}
              </>
            )}
          </section>
        </>
      ) : (
        <>
          <FullPageLoader />
        </>
      )}
    </>
  );
};

export default ProductInfo;
