import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/MutoPad.svg';
// import quil from '../assets/images/quill-audits.png';
// import certik from '../assets/images/certick.png'
import { useDispatch, useSelector } from 'react-redux';
import { getSetting } from '../redux/setting/setting.action';
import { cmsPageListing } from '../redux/cms/cms.action';
import { useAccount } from 'wagmi';
import Swal from 'sweetalert2';

const Footer = () => {
    
    var d = new Date();
    d = d.getFullYear();

    const navigate = useNavigate()
    const {address} = useAccount();
   
    const dispatch = useDispatch()
    const settings = useSelector((state) => state?.setting?.setting)
    const cmsPages = useSelector((state) => state?.cms?.cmsPageList)

    useEffect(() => {
        dispatch(getSetting())
    }, [dispatch])

    useEffect(() => {
        dispatch(cmsPageListing())
    }, [dispatch])

    const handleScroll = () => {
        window.scroll(0, 0)
    }

    const createIdo = () => {
        if(address === '' || address === undefined){
        Swal.fire(
            'Wallet Not Connected!',
            'Please connect your wallet then proceed.',
            'error'
        )
        } else {
        navigate('/admin/managepools')
        window.scroll(0,0)
        }
    }

    const handleScrollWindow = () =>{
        window.scroll(0,0)
    }

    return <>
        <footer>
            <div className="footer-header">
                <div className="container-fluid custom-block">
                    <div className="row">
                        <div className="col-lg-5 mb-lg-0 mb-4">
                            <figure className="footer-mb footer-logo">
                                <Link to='/'>
                                <span onClick={handleScroll}>
                                    <img src={Logo} alt="sitelogo" className="img-fluid" style={{ height: "auto", width: "auto" }} />
                                </span>
                                </Link>
                            </figure>
                            <p className="footer-mb">{settings?.metaDescription}</p>
                            <ul className="social-icons">
                                {settings?.telegramLink &&
                                    <li>
                                        <a href={`${settings?.telegramLink}`} target="_blank" rel="noopener noreferrer">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                }
                                {settings?.mediumLink &&
                                    <li>
                                        <a href={`${settings?.mediumLink}`} target="_blank" rel="noopener noreferrer">
                                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 50 50" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M45,4H5C4.448,4,4,4.448,4,5v40c0,0.552,0.448,1,1,1h40c0.552,0,1-0.448,1-1V5C46,4.448,45.552,4,45,4z M40,13.5 l-1.821,2.197C38.064,15.811,38,15.965,38,16.125V32.75c0,0.16,0.064,0.314,0.179,0.428L40,35.546V36H30v-0.454l2.821-2.368 C32.936,33.064,33,32.91,33,32.75V17.879L24.848,36h-0.001h-1.543l0,0L15,18.375v13.108c0,0.22,0.076,0.433,0.215,0.605L18,35.546 V36h-8v-0.454l2.783-3.438C12.923,31.936,13,31.721,13,31.5V16.388c0-0.142-0.05-0.279-0.142-0.388L11,13.5V13h7.097l7.624,16.183 L33.002,13H40V13.5z" />
                                            </svg>
                                        </a>
                                    </li>
                                }
                                {settings?.twitterLink &&
                                    <li>
                                        <a href={`${settings?.twitterLink}`} target="_blank" rel="noopener noreferrer">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                }
                                {settings?.podCastLink &&
                                    <li>
                                        <a href={`${settings?.podCastLink}`} target="_blank" rel="noopener noreferrer">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M192 352c53.03 0 96-42.97 96-96h-80C199.2 256 192 248.8 192 240S199.2 224 208 224H288V192h-80C199.2 192 192 184.8 192 176S199.2 160 208 160H288V127.1h-80c-8.836 0-16-7.164-16-16s7.164-16 16-16L288 96c0-53.03-42.97-96-96-96s-96 42.97-96 96v160C96 309 138.1 352 192 352zM344 192C330.7 192 320 202.7 320 215.1V256c0 73.33-61.97 132.4-136.3 127.7c-66.08-4.169-119.7-66.59-119.7-132.8L64 215.1C64 202.7 53.25 192 40 192S16 202.7 16 215.1v32.15c0 89.66 63.97 169.6 152 181.7V464H128c-18.19 0-32.84 15.18-31.96 33.57C96.43 505.8 103.8 512 112 512h160c8.222 0 15.57-6.216 15.96-14.43C288.8 479.2 274.2 464 256 464h-40v-33.77C301.7 418.5 368 344.9 368 256V215.1C368 202.7 357.3 192 344 192z" />
                                            </svg>
                                        </a>
                                    </li>
                                }
                                {settings?.websiteLink &&
                                    <li>
                                        <a href={`${settings?.websiteLink}`} target="_blank" rel="noopener noreferrer">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" height="1em" width="1em" enableBackground="new 0 0 500 500" id="Layer_1" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                                                <path clipRule="evenodd" d="M31.949,250c0,120.473,97.58,218.051,218.051,218.051  c120.473,0,218.051-97.578,218.051-218.051c0-120.471-97.578-218.051-218.051-218.051C129.529,31.949,31.949,129.529,31.949,250z   M77.375,250c0-13.992,1.638-27.62,4.814-40.61L122.802,250l-9.084,54.513l36.342,36.34v49.883  C106.088,359.48,77.375,308.063,77.375,250z M210.295,82.007c12.72-3.087,25.988-4.631,39.705-4.631c15.173,0,29.891,2,43.883,5.634  l-7.542,12.539l9.086,9.084h27.258l7.543-7.541c6.35,3.362,12.35,6.996,18.169,11.084l-16.625,5.542l-18.173,18.17l18.173,9.086  v9.086h-18.173v18.17c0,0,12.536,9.086,27.254,9.086c14.448,0,7.998-19.897,18.174-27.256c10.267-7.361,22.715-5.724,22.715-5.724  l5.906,1.544c11.085,14.626,19.892,30.984,25.891,48.697v0.909c0,0-12.812-9.086-36.34-9.086c-23.53,0-45.427,9.086-45.427,9.086  s-25.627,7.453-27.259,27.257c-2.82,34.069,9.086,45.428,9.086,45.428l45.428,27.255v88.408  C329.322,408.09,291.339,422.624,250,422.624c-19.532,0-38.249-3.174-55.785-9.174l46.699-108.938l-9.084-45.427  c0,0-36.071-45.428-54.513-45.428c-18.441,0-27.256,27.256-27.256,27.256l-18.172-36.34l54.512-36.343l27.257-72.683L210.295,82.007  z" fillRule="evenodd" />
                                            </svg>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className='col-lg-7 mb-0'>
                            <div className='row offset-lg-2 offset-0'>
                                <div className="col-lg-4 mb-lg-0 mb-3">
                                    <h3 className="footer-mb text-capitalize">General</h3>
                                    <ul className="footer-links">
                                        <li onClick={createIdo}><Link>Apply for IDO</Link></li>
                                        <li className=""><Link to="/privacy" onClick={handleScrollWindow}>Privacy Policy</Link></li>
                                        <li className=""><Link to="/tos" onClick={handleScrollWindow}>Terms of Use</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 mb-lg-0 mb-3">
                                    <h3 className="footer-mb text-capitalize">Help</h3>
                                    <ul className="footer-links">
                                        <li><Link to="/faqs">FAQs</Link></li>
                                    </ul>
                                </div>
                                {
                                    cmsPages.length > 0 ? 
                                    <>
                                        <div className="col-lg-4 mb-lg-0 mb-3">
                                            <h3 className="footer-mb text-capitalize">CMS Pages</h3>
                                            <ul className="footer-links">
                                                {cmsPages.filter(res => res.pageStatus === true && res.showLinkInFooter === true).map((link, index) => (
                                                    <li key={index}>
                                                        <Link to={`cms/${link.pageTitle}`}>
                                                            <span className='text-capitalize'>{link.pageTitle?.split("-").join(" ")}</span>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container-fluid custom-block">
                    <div className="footer-bottom-inner">
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-6 mb-0">
                                <p className="copyright mb-sm-0 mb-2">© Copyright MutoPad {d}. All rights reserved.</p>
                            </div>
                            <div className="col-lg-3 col-sm-6 mb-0 md-none">
                                <ul className="footer-links">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
};

export default Footer;