import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header';
import Landing from './components/landingPage/landingPage';
import Product from './components/ProductPage/ProductPage';
import Terms from './components/Terms/Terms';
import Privacy from './components/Privacy/Privacy';
import Faqs from './components/FaqsPage/Faqs';
import Admin from './components/AdminPanel/admin';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getSetting } from './redux/setting/setting.action';
import CMSPageView from './components/AdminPanel/CMSPAGES/CMSPageView';
import Footer from './components/footer';

function App() {
  const dispatch = useDispatch();
  const settingDetail = useSelector((state) => state?.setting?.setting);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta name="description" content={`${settingDetail?.siteDescription?.slice(3, -4)}`} />
        <meta name="keywords" content={`${settingDetail?.metaKeyword}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={`${settingDetail?.metaDescription}`} />
        <meta name="twitter:creator" content={`${settingDetail?.siteTitle}`} />
        <meta name="author" content={`${settingDetail?.siteTitle}`} />
        <meta property="og:title" content={`${settingDetail?.siteTitle}`} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={`${settingDetail?.metaDescription}`} />
        {/* facebook */}
      </Helmet>
      <div className="App">
        <div className="wrapper">
          <Header />
          <main>
            <Routes>
              <Route path="/" exact element={<Landing />} />
              <Route path="/pool/:poolId" exact element={<Product />} />
              <Route path="/cms/:pageTitle" element={<CMSPageView />} />
              <Route path="/tos" exact element={<Terms />} />
              <Route path="/privacy" exact element={<Privacy />} />
              <Route path="/faqs" exact element={<Faqs />} />
              <Route path="/admin/*" exact element={<Admin />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
