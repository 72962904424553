import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi';
import Swal from 'sweetalert2';

const YourProject = () => {
    const navigate = useNavigate()
    const { address } = useAccount();
    const createIdo = () => {
        if (address === '' || address === undefined) {
            Swal.fire(
                'Wallet Not Connected!',
                'Please connect your wallet then proceed.',
                'error'
            )
        } else {
            navigate('/admin/managepools')
        }
    }
    return <>
        <section className="your-project">
            <div className="container-fluid custom-block">
                <div className="project-bg">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="width-wrap">
                            <p className="text-uppercase space-p">Want to launch</p>
                            <h2 className="h2 text-uppercase">your project on SafePad?</h2>
                        </div>
                        <button className='d-inline theme-btn' onClick={createIdo}>
                            <span>Apply To Launch</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </>
};

export default YourProject;
