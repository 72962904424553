/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExternalLinkAlt, faBars, faLessThanEqual, faMinus, faTags, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  poolsListing,
  markScamPool,
  unMarkScamPool,
  updateState,
  updateFeatureStatus,
  toCancelPool,
  addToWhiteList,
  getWhiteListAddresses,
  toDeletePool,
  settelPoolCreate,
} from '../../../redux/pool/pool.action';
import moment from 'moment';
import ApproveReject from './ApproveReject';
import Web3 from 'web3';
import ContractAbi from '../../../utils/contractAbi.json';
import ethContractAbi from '../../../utils/ethContractAbi.json';
import ModalLoading from '../../ProductPage/loadingModal';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { useAccount, useNetwork } from 'wagmi';
import { encode } from 'js-base64';
import Dropdown from 'react-bootstrap/Dropdown';
import { Sparklines, SparklinesBars, SparklinesLine } from 'react-sparklines';
import { MerkleTree } from 'merkletreejs';
import SHA256 from 'crypto-js/sha256';
import { platformSupportedChains, objectToQueryString } from '../../../config/config';
import { BigNumber, ethers } from 'ethers';
import io from 'socket.io-client';
import FullPageLoader from '../../Loaders/fullpageloader';
const socket = io.connect('http://localhost:5000');

function ManagePools({ walletAddress }) {
  //** libraries & env keys **//
  const ownerAddress = process.env.REACT_APP_OWNER_ADDRESS;
  const searchQuery = new URLSearchParams(location.search);
  const { address } = useAccount();
  const navigate = useNavigate();
  const { chain } = useNetwork();
  let addressError = '';
  const dispatch = useDispatch();

  //** all state variables **//
  const [show, setShow] = useState(false);
  const [userLoginAcc, setUserLoginAcc] = useState('');
  const [currentPoolData, setCurrentPoolData] = useState('');
  const [whiteListPoolId, setWhiteListPoolId] = useState('');
  const [scamShow, setScamShow] = useState(false);
  const [unScamShow, setUnScamShow] = useState(false);
  const [whiteListAddresses, setWhiteListAddresses] = useState();
  const [whiteListUsersDoc, setWhiteListUsersDoc] = useState();
  const [poolType, setPoolType] = useState('');
  const [loading, setLoading] = useState(false);
  const [scamState, setScamState] = useState({ message: '' });
  const [whitListShow, setWhitListShow] = useState(false);
  const [arrayForGraph, setArrayForGraph] = useState(null);
  const [methodType, setMethodType] = useState('');
  const [docLoading, setDocLoading] = useState(false);
  const [initialOrder, setInitialOrder] = useState({});
  const [clearingPriceOrder, setClearingPriceOrder] = useState({});
  const [clearingPrice, setClearingPrice] = useState('');
  const [initialPrice, setInitialPrice] = useState('');
  const [thresholdNotReached, setThresholdNotReached] = useState(true);
  const [filterMsgCheck, setFilterMsgCheck] = useState(false);
  const [filters, setFilters] = useState({
    poolName: searchQuery.get('poolName'),
  });
  const [eventData, setEventData] = useState(null);
  const [loader, setLoader] = useState(true);

  //** Redux States **//
  const pools = useSelector(state => state?.pool?.poolsList);
  const pagination = useSelector(state => state?.pool?.pagination);
  const whiteListAddressesData = useSelector(state => state?.pool?.whiteListAddresses);
  const isScamPool = useSelector(state => state?.pool?.isScamPool);
  const isEditScamPool = useSelector(state => state?.pool?.isEditScamPool);
  const featureSuccess = useSelector(state => state?.pool?.featureSuccess);
  const delAuth = useSelector(state => state?.pool?.delAuth);
  const isCancelled = useSelector(state => state?.pool?.isCancelled);
  const offsetInSeconds = new Date().getTimezoneOffset() * 60 * -1;

  //** useeffects & dependencies **//
  useEffect(() => {
    socket.on('connectedUser', data => {
    });
    socket.on('poolCreated', data => {
      setEventData(data);
    });
  }, [socket]);

  useEffect(() => {
    if (pools) {
      let arr = [];
      pools?.thresholdData?.forEach(function (item) {
        arr.push(item?.minBuyAmounts);
      });
      arr.sort(function (a, b) {
        return a - b;
      });
      setArrayForGraph(arr);
      // setLoader(false)
    }
  }, [pools]);

  useEffect(() => {
    dispatch(poolsListing(userLoginAcc));
  }, [show, userLoginAcc, dispatch]);

  useEffect(() => {
    if (isScamPool === true) dispatch(poolsListing(userLoginAcc));
    dispatch(updateState());
  }, [show, userLoginAcc, dispatch, isScamPool]);

  useEffect(() => {
    if (isCancelled === true) dispatch(poolsListing(userLoginAcc));
    dispatch(updateState());
  }, [show, userLoginAcc, dispatch, isCancelled]);

  useEffect(() => {
    if (delAuth === true) dispatch(poolsListing(userLoginAcc));
    dispatch(updateState());
  }, [show, userLoginAcc, dispatch, delAuth]);

  useEffect(() => {
    if (isEditScamPool === true) dispatch(poolsListing(userLoginAcc));
    dispatch(updateState());
  }, [show, userLoginAcc, dispatch, isEditScamPool]);

  useEffect(() => {
    if (address !== undefined) {
      setUserLoginAcc(address.toLocaleLowerCase());
    }
  }, [address, userLoginAcc]);

  useEffect(() => {
    if (featureSuccess) {
      dispatch(poolsListing(userLoginAcc));
      dispatch(updateState());
    }
  }, [featureSuccess, dispatch, userLoginAcc]);

  useEffect(() => {
    if (whiteListAddressesData) {
      setWhiteListAddresses(whiteListAddressesData.whiteListUsers?.join(','));
      setPoolType(whiteListAddressesData.poolType);
    }
  }, [whiteListAddressesData]);

  useEffect(() => {
    searchDoc(1);
  }, [filters]);

  useEffect(() => {
    // Fetch items from another resources.
    const startPoint = 0;
    const endPoint = pagination?.limit - 1;
  }, [pools, filters]);

  //** logical function handlers **//
  const contractHandler = (Abi, tokenAdd) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(Abi, tokenAdd);
    return contract;
  };

  const handleModal = () => {
    setShow(false);
  };

  const deletePool = (e, poolId, _id) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure want to delete it?',
      text: 'Once You deleted it could be removed or disabled permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        setLoading(true);
        let chainContract = platformSupportedChains[chain.id];
        let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
        try {
          let res = await mutoPool.methods.deletPool(poolId.toString()).send({ from: window.ethereum.selectedAddress });
          if (res) {
            let payload = {
              id: _id,
              isDeleted: true,
            };
            let rest = dispatch(toDeletePool(payload));
            setLoading(false);
            if (rest) {
              Swal.fire('Deleted!', 'Pool has been deleted.', 'success');
            }
          }
        } catch (e) {
          setLoading(false);
          Swal.fire('Failed! transaction has been Reverted', 'Delete Pool Action not Successful', 'error');
        }
      }
    });
  };

  const featureHandle = (status, poolId) => {
    Swal.fire({
      title: '',
      text: `${status == undefined || status === false ? 'Are you sure want to add it into featured pools?' : 'Are you sure want to remove it from featured pools'}`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'success',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        // deletePool()
        if (status === undefined || status === false) {
          let data = {
            featureStatus: true,
          };
          dispatch(updateFeatureStatus(poolId, data));
          Swal.fire('Added!', 'Added to featured pool successfully.', 'success');
        } else {
          let data = {
            featureStatus: false,
          };
          dispatch(updateFeatureStatus(poolId, data));
          Swal.fire('Removed!', 'Removed from featured pool successfully.', 'success');
        }
      }
    });
  };

  const approveRejectHandler = item => {
    setShow(true);
    setCurrentPoolData(item);
  };

  const markScam = async e => {
    try {
      e.preventDefault();
      if (scamState !== '' && scamState !== undefined && scamState?.poolId !== undefined) {
        setScamShow(false);
        setLoading(true);
        let chainContract = platformSupportedChains[chain.id];
        let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
        let res = await mutoPool.methods.markSpam((scamState?.poolId).toString()).send({ from: window.ethereum.selectedAddress });
        if (res.blockHash) {
          let payload = {
            id: scamState?.id,
            scamMessage: scamState?.message,
            scamStatus: true,
          };
          let resdt = dispatch(markScamPool(payload));
          if (resdt) {
            setLoading(false);
          }
        }
      }
    } catch (e) {
      setLoading(false);
      Swal.fire('Transaction Failed!', 'Scam Pool transaction has been reverted:)', 'error');
    }
  };

  const unMarkScam = async e => {
    setUnScamShow(false);
    setLoading(true);
    e.preventDefault();
    try {
      if (scamState.poolId !== undefined && scamState.poolId !== '') {
        let payload = {
          id: scamState.id,
          scamStatus: scamState.scamStatus,
          scamMessage: '',
        };
        let resdt = dispatch(unMarkScamPool(payload));
        if (resdt) {
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      Swal.fire('Transaction Failed!', 'UnScam Pool transaction has been reverted:', 'error');
    }
  };

  const cancelPool = (e, poolId, id) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: 'By Cancelling Pool takes your contribution (with 10% penalty) out of Presale Pool and cancel your participation from Pool!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancel!',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          let chainContract = platformSupportedChains[chain.id];
          let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
          let res = await mutoPool.methods.markCancel(poolId.toString()).send({ from: window.ethereum.selectedAddress });
          if (res.blockHash) {
            let payload = {
              id: id,
              isCancelled: true,
            };
            let resdt = dispatch(toCancelPool(payload));
            if (resdt) {
              setLoading(false);
              Swal.fire('Cancelled!', 'Pool has been Cancelled.', 'success');
            }
          }
        } catch (e) {
          setLoading(false);
          Swal.fire('Transaction Failed!', 'Cancel Pool transaction has been reverted', 'error');
        }
      }
    });
  };

  const editPool = item => {
    if (((parseInt(item?.poolEndDate) + offsetInSeconds)  - moment(Date.now()).unix()) < 60 ) {
      Swal.fire('Sorry!', "Pool edit time ended", 'warning');
    } else {
      navigate(`/admin/edit-pool/${encode(item.poolId)}`);
    }
  };

  const whitListHandler = async (e, previousUsers, methodType, merkTree, trxHash) => {
    e.preventDefault();
    let addresses = whiteListUsersDoc?.split(',');
    if (methodType === 'add') {
      try {
        let regexp = /^(?:[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)?$/;
        if (whiteListUsersDoc !== '' && regexp.test(whiteListUsersDoc)) {
          let addresses = whiteListUsersDoc?.split(',');
          for (let i = 0; i < addresses.length; i++) {
            if (!ethers.utils.isAddress(addresses[i])) {
              addressError = `${addresses[i]} invalid address`;
              Swal.fire('Invalid Address!', `${addresses[i]}`, 'error');
              break;
            }
          }
          if (addressError === '') {
            const tree = new MerkleTree(addresses, SHA256);
            const root = tree.getRoot().toString('hex');
            const hexRoot = tree.getHexRoot();
            let chainContract = platformSupportedChains[chain.id];
            let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
            let merkleTree = hexRoot;
            let res = await mutoPool.methods.whitelistUpdate(whiteListPoolId, merkleTree).send({ from: window.ethereum.selectedAddress });
            if (res.blockHash) {
              for (let k = 0; k < addresses.length; k++) {
                if (previousUsers.includes(addresses[k]) !== true) {
                  previousUsers.push(addresses[k]);
                }
              }
              let payload = {
                poolId: whiteListPoolId,
                transactionHash: res.transactionHash,
                whiteListUsers: previousUsers,
                poolType: poolType,
                merkleTree: merkleTree,
                methodType: methodType,
              };
              dispatch(addToWhiteList(payload));
              setWhitListShow(false);
              setWhiteListUsersDoc();
              setMethodType('');
            }
          }
        }
      } catch (e) {
        Swal.fire('Transaction Failed!', 'Add to whitelist transaction has been reverted', 'error');
      }
    } else if (methodType === 'remove') {
      try {
        let regexp = /^(?:[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)?$/;
        if (whiteListUsersDoc !== '' && regexp.test(whiteListUsersDoc)) {
          let addresses = whiteListUsersDoc?.split(',');
          for (let i = 0; i < addresses.length; i++) {
            if (!ethers.utils.isAddress(addresses[i])) {
              addressError = `${addresses[i]} invalid address`;
              Swal.fire('Invalid Address!', `${addresses[i]}`, 'error');
              break;
            }
          }
          if (addressError === '') {
            for (let j = 0; j < addresses.length; j++) {
              if (previousUsers.includes(addresses[j]) === true) {
                const index = previousUsers.indexOf(addresses[j]);
                previousUsers.splice(index, 1);
              }
            }
            const tree = new MerkleTree(addresses, SHA256);
            const root = tree.getRoot().toString('hex');
            const hexRoot = tree.getHexRoot();
            let chainContract = platformSupportedChains[chain.id];
            let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
            let merkleTree = hexRoot;
            let res = await mutoPool.methods.whitelistUpdate(whiteListPoolId, merkleTree).send({ from: window.ethereum.selectedAddress });
            if (res.blockHash) {
              let payload = {
                poolId: whiteListPoolId,
                transactionHash: res.transactionHash,
                whiteListUsers: previousUsers,
                poolType: poolType,
                merkleTree: merkleTree,
                methodType: methodType,
              };
              dispatch(addToWhiteList(payload));
              setWhitListShow(false);
              setWhiteListUsersDoc();
              setMethodType('');
            }
          }
        }
      } catch (e) {
        Swal.fire('Transaction Failed!', 'Add to whitelist transaction has been reverted', 'error');
      }
    } else if (methodType === 'saletype') {
      try {
        let payload = {
          poolId: whiteListPoolId,
          transactionHash: trxHash,
          whiteListUsers: previousUsers,
          poolType: poolType,
          merkleTree: merkTree,
          methodType: methodType,
        };
        dispatch(addToWhiteList(payload));
        setWhitListShow(false);
        setWhiteListUsersDoc();
        setMethodType('');
      } catch (e) {
        Swal.fire('Transaction Failed!', 'Add to whitelist transaction has been reverted', 'error');
      }
    }
  };

  async function getContractData(poolId) {
    let orderObject = {};
    const chainContract = platformSupportedChains[chain?.id];
    const mutoPool = contractHandler(ethContractAbi, chainContract?.poolContract);
    const initialOrder = await mutoPool.methods.poolData(poolId).call();
    orderObject.initialOrder = await initialOrder[3];
    orderObject.clearingOrder = await initialOrder[6];
    let res = await decodeOrder(orderObject?.clearingOrder);
    let clrearPrice = res?.sellAmount / res?.buyAmount;
    setClearingPrice(clrearPrice.toString());
    orderObject.clearingPrice = clrearPrice;
    let res1 = await decodeOrder(orderObject?.initialOrder);
    let initPrice = res1?.sellAmount / res1?.buyAmount;
    orderObject.initPrice = initPrice;
    setInitialPrice(initPrice);
    setClearingPriceOrder(orderObject.clearingOrder);
    setInitialOrder(orderObject.initialOrder);
    setThresholdNotReached(initialOrder[1].minFundingThresholdNotReached);
    return orderObject;
  }

  async function decodeOrder(bytes) {
    if (bytes === undefined) {
      return {
        userId: 0,
        sellAmount: 0,
        buyAmount: 0,
      };
    }
    let userId = BigNumber.from('0x' + bytes.substring(2, 18)).toString();
    let sellAmount = BigNumber.from('0x' + bytes.substring(43, 66)).toString();
    let buyAmount = BigNumber.from('0x' + bytes.substring(19, 42)).toString();
    return {
      userId,
      sellAmount,
      buyAmount,
    };
  }

  const closeClearPool = async (e, item) => {
    // debugger;
    setLoading(true);
    e.preventDefault();
    let PoolId = item?.poolId;
    let gtd = await getContractData(item?.poolId);
    let chainContract = platformSupportedChains[chain.id];
    let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
    if (PoolId !== undefined && PoolId !== '') {
      try {
        let res = await mutoPool.methods.settlePool(parseInt(PoolId)).send({ from: window.ethereum.selectedAddress });
        var pricesData = await getContractData(item?.poolId);
        if (res.blockHash) {
          let newObj = {
            poolId: item?._id,
            from: res?.from,
            to: res?.to,
            blockHash: res?.events?.PoolCleared?.blockHash,
            blockNumber: res?.events?.PoolCleared?.blockNumber,
            event: res?.events?.PoolCleared?.event,
            id: res?.events?.PoolCleared?.id,
            bnPoolId: res?.events?.PoolCleared?.returnValues?.poolId,
            soldBiddingTokens: res?.events?.PoolCleared?.returnValues?.soldBiddingTokens,
            soldPoolingTokens: res?.events?.PoolCleared?.returnValues?.soldBiddingTokens,
            transactionHash: res?.transactionHash,
            transactionIndex: res?.transactionIndex,
            clearingPrice: (pricesData?.clearingPrice).toString(),
          };
          let resdt = await dispatch(settelPoolCreate(newObj));
          setLoading(false);
          Swal.fire('Pool Settled!', 'Pool settled successfully.', 'success');
        }
      } catch (e) {
        setLoading(false);
        Swal.fire('Transaction Failed!', 'Settle Pool transaction has been reverted.', 'error');
      }
    }
    // }
  };

  const handleSearch = e => {
    e.preventDefault();
    const { name } = e.target;
    const obj = { ...filters };
    if (name === 'poolName') {
      obj.poolName = e.target.value;
    }
    if (name === 'poolName') {
      setFilterMsgCheck(false);
      setFilters(obj);
    } else {
      setFilterMsgCheck(true);
    }
  };

  const searchDoc = async page => {
    if (page === undefined || page === '') page = 1;
    const params = {};
    if (filters.poolName) params.poolName = filters.poolName;
    const qs = objectToQueryString({ ...params, page });
    dispatch(poolsListing(userLoginAcc, qs));
  };

  const whiteListCallFun = (item) =>{
    setWhitListShow(true);
    setWhiteListPoolId(item?.poolId);
    dispatch(getWhiteListAddresses(item?.poolId));
  }

  return (
    <div className='mutopad-w-100'>
      <div className='content'>
        <div className='manage-pools-pg'>
          <Card className='pt-lg-4 pt-3 h-auto manage-card-table' id='style-6'>
            <Card.Header className='titles-sett'>
              <h2 className='text-shadow'>Manage Pools</h2>
              <div className='sm-right-btn'>
                <Link className='d-inline light-blue-btn text-capitalize' to='/admin/create-pool'>
                  <FontAwesomeIcon className='add-icon' icon={faPlus} /> Add Pool
                </Link>
              </div>
            </Card.Header>
            {loader && setTimeout(() => setLoader(false), 2000) ? (
              <div className='text-center faq-loader'>
                {/* <TailSpin height='100' width='100' color='#46bdf4' ariaLabel='loading' /> */}
                <FullPageLoader/>
              </div>
            ) : (
              <Card.Body>
                <div className='ms-2'>
                  <Form
                    className='row mt-4'
                    onSubmit={e => {
                      e.preventDefault();
                      searchDoc();
                      filters?.poolName ? <></> : setFilterMsgCheck(true);
                    }}
                  >
                    <div className='col-md-3 me-2'>
                      {/* <label htmlFor='searchName' style={{ color: 'white' }}>
                        Pool Name
                      </label> */}
                      <div className='pools-search'>
                        <div className='position-relative'>
                          <input
                            name='poolName'
                            type='text'
                            defaultValue={filters.poolName === null ? '' : filters?.poolName}
                            className='form-control'
                            placeholder='Search by pool name...'
                            onChange={handleSearch}
                          />
                          <FontAwesomeIcon icon={faSearch} className='text-white' />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-2 mt-4 ms-3'>
                      {/* {( filters.poolName ) && <button className="btn btn-info mr-3 btn-warning" type="button" onClick={resetFilter}
                              >Reset Filters</button>} */}
                      {/* <button style={{ color: 'white' }} className='btn btn-warning' type='submit' disabled={filters?.poolName ? false : true}>
                            Search
                          </button> */}
                    </div>
                    <div className='col-md-12'>
                      <span className={filterMsgCheck ? `` : `d-none`}>
                        <label className='pt-3 text-danger'>Please fill a filter field to perform filteration</label>
                      </span>
                    </div>
                  </Form>
                </div>
                <div className='table-responsive manage-pools'>
                  <Table>
                    <thead>
                      <tr className='text-center'>
                        <th>Pool</th>
                        <th>Name</th>
                        <th>Tokenomics</th>
                        <th>Scheduled</th>
                        <th>Activity</th>
                        <th>Pool Status</th>
                        <th>KYC Status</th>
                        {walletAddress === ownerAddress ? (
                          <>
                            <th>Fetaured Status </th>
                          </>
                        ) : (
                          <></>
                        )}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pools && pools?.length > 0 ? (
                        pools.map((item, index) => {
                          return (
                            <tr key={index} className='text-center'>
                              <td align='left'>
                                {item?.isDeleted === true ? (
                                  <>
                                    <Link
                                      style={{ color: 'white' }}
                                      onClick={() => {
                                        Swal.fire('Failed! Current Pool Has been Deleted/Disabled', 'Please Contact With Admin:)', 'error');
                                      }}
                                    >
                                      <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} />{' '}
                                      <small>
                                        0x...
                                        {item.transactionHash?.substring(39, 42)}
                                      </small>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to={`/pool/${encode(item.poolId)}`} style={{ color: 'white' }}>
                                      <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} />{' '}
                                      <small>
                                        0x...
                                        {item.transactionHash?.substring(39, 42)}
                                      </small>
                                    </Link>
                                  </>
                                )}
                                <br />
                                <small className='align-left'>{item.network}</small>
                              </td>
                              <td>
                                <span style={{ fontSize: '16px' }}>{item.title}</span>
                                <br />
                                <span>
                                  <small className='tbl_pool_name'>by: {item.name}</small>
                                </span>
                              </td>
                              <td>
                                <small className='tbl_pool_tokenomics'>
                                  Minimum Bidding Amount: {item.minimumBiddingAmount} {item.bdSymbol}
                                </small>
                                <small className='tbl_pool_tokenomics'>
                                  Minimum Buy Amount: {item.minimumBuyAmount} {item.ptSymbol}
                                </small>
                                <small className='tbl_pool_tokenomics'>
                                  Threshold: {item.minimumFundingThreshold} {item.bdSymbol}
                                </small>
                              </td>
                              <td>
                                <span className='tbl_pool_time'>
                                  {moment.unix(item.poolStartDate).format('DD MMMM YYYY, HH:mm a')} UTC
                                  <br />
                                  to
                                  <br />
                                  {moment.unix(item.poolEndDate).format('DD MMMM YYYY, HH:mm a')} UTC
                                </span>
                              </td>
                              <td style={{ width: '110px' }}>
                                {item.soldArrayCounter != undefined && item.soldArrayCounter.length > 0 ? (
                                  <>
                                    <Sparklines data={item.soldArrayCounter} width={150} height={100}>
                                      <SparklinesBars style={{ stroke: '#FDDF7C', fill: '#A948BF', fillOpacity: '.50' }} />
                                      {/* <SparklinesLine style={{ stroke: 'green', strokeWidth: 1, fill: 'none' }} /> */}
                                    </Sparklines>
                                  </>
                                ) : (
                                  <>
                                    <Sparklines data={[0, 0]} width={150} height={100}>
                                      <SparklinesLine style={{ stroke: '#FDDF7C', fill: 'transparent', fillOpacity: '.50' }} />
                                      {/* <SparklinesLine style={{ stroke: 'green', strokeWidth: 1, fill: 'none' }} /> */}
                                    </Sparklines>
                                  </>
                                )}
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='badges' style={{ margin: '0' }}>
                                    {item.isDeleted === true ? (
                                      <span className='outerside soon'>Deleted</span>
                                    ) : item.scamStatus === true ? (
                                      <span className='outerside soon'>Scammed</span>
                                    ) : item.isCancelled === true ? (
                                      <span className='outerside soon'>Cancelled</span>
                                    ) : item.closeStatus === true ? (
                                      <span className='outerside soon'>Closed</span>
                                    ) : (
                                      <span className='outerside level'>Active</span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='badges d-flex justify-content-center' style={{ margin: '0' }}>
                                  <div>
                                    {item?.poolStatus === true ? (
                                      <>
                                        <span className='outerside level'>Approved</span>{' '}
                                      </>
                                    ) : item?.poolStatus === false ? (
                                      <>
                                        <span className='outerside soon'> Rejected</span>
                                      </>
                                    ) : (
                                      <>
                                        <span className='outerside kyc me-2'>Pending</span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                              {walletAddress === ownerAddress ? (
                                <>
                                  <td>
                                    <div className='badges' style={{ margin: '0' }}>
                                      {item?.featureStatus === true ? (
                                        <span className='outerside level'> Featured</span>
                                      ) : item?.featureStatus === false ? (
                                        <span className='outerside soon'> Not Featured </span>
                                      ) : (
                                        <>
                                          <span className='outerside kyc'>Pending</span>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}

                              <td>
                                <Dropdown size='sm' className='d-inline-block manage-pools-dd'>
                                  <Dropdown.Toggle size='sm' variant='default' id='dropdown-basic' className='text-light'>
                                    <FontAwesomeIcon className='del-icon' icon={faBars} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu key='up' id={`dropdown-button-drop-up`} drop='up' variant='dark' size='sm'>
                                    {item?.poolOwner === address?.toLowerCase() ? 
                                    <Dropdown.Item onClick={() => editPool(item)}>Edit</Dropdown.Item>
                                    :
                                    <></>
                                    }
                                    {(parseInt(item?.poolEndDate) + offsetInSeconds) < moment(Date.now()).unix() && item?.closeStatus === false ? (
                                      <>
                                        <Dropdown.Item onClick={e => closeClearPool(e, item)}>Settle Pool</Dropdown.Item>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  
                                    {
                                      address.toLowerCase() === item?.poolOwner ?
                                      <>
                                       <Dropdown.Item
                                          onClick={(i) => {
                                            ((parseInt(item?.poolEndDate ) + offsetInSeconds)- moment(Date.now()).unix()) > 120 ? whiteListCallFun(item)
                                              :
                                              ((parseInt(item?.poolEndDate ) + offsetInSeconds) - moment(Date.now()).unix()) < 120 && (parseInt(item?.poolEndDate) + offsetInSeconds) - moment(Date.now()).unix() > 10 ?
                                              Swal.fire('Times Up! 🕛', 'Pool is going to end in a while.')
                                              :
                                              Swal.fire('Pool Ended! 🕛', 'Pool is not live.')
                                          }}
                                        >
                                          WhiteList
                                        </Dropdown.Item>
                                      </>
                                      :
                                      <></>
                                    }
                                    {walletAddress === ownerAddress ? (
                                      <>
                                        {(parseInt(item?.poolStartDate) + offsetInSeconds) > moment(Date.now()).unix()? (
                                          <>
                                            <Dropdown.Item disabled={item?.isDeleted === true ? true : false} onClick={e => deletePool(e, item?.poolId, item?._id)}>
                                              Delete
                                            </Dropdown.Item>
                                          </>
                                        ) : (
                                          <>
                                            <Dropdown.Item
                                              onClick={() => {
                                                Swal.fire('Sorry!', 'Pool delete time ended', 'warning');
                                              }}
                                            >
                                              Delete
                                            </Dropdown.Item>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {walletAddress === ownerAddress ? (
                                      <>
                                        {item?.scamStatus === false ? (
                                          <>
                                            <Dropdown.Item
                                              onClick={() => {
                                                setScamState({
                                                  ...scamState,
                                                  title: item?.title,
                                                  id: item?._id,
                                                  poolId: item?.poolId,
                                                }),
                                                  setScamShow(true);
                                              }}
                                            >
                                              Scam
                                            </Dropdown.Item>
                                          </>
                                        ) : (
                                          <>
                                            <Dropdown.Item
                                              onClick={() => {
                                                setScamState({
                                                  ...scamState,
                                                  title: item?.title,
                                                  id: item?._id,
                                                  poolId: item?.poolId,
                                                  scamStatus: item?.scamStatus,
                                                }),
                                                  setUnScamShow(true);
                                              }}
                                            >
                                              Unscam
                                            </Dropdown.Item>
                                          </>
                                        )}
                                        <> </>
                                        <Dropdown.Item
                                          onClick={() => {
                                            approveRejectHandler(item);
                                          }}
                                        >
                                          KYC
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => {
                                            featureHandle(item.featureStatus, item.poolId);
                                          }}
                                        >
                                          {item?.featureStatus === true ? 'UnFeatured' : 'Featured'}
                                        </Dropdown.Item>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {item?.poolOwner === address?.toLocaleLowerCase() && (parseInt(item?.poolCancelDate) + offsetInSeconds) > moment(Date.now()).unix() ? (
                                      <>
                                        <Dropdown.Item onClick={e => cancelPool(e, item?.poolId, item?._id)}>Cancel</Dropdown.Item>
                                      </>
                                    ) : (
                                      <>
                                        {item?.poolOwner === address?.toLocaleLowerCase() ? (
                                          <Dropdown.Item
                                            onClick={() => {
                                              Swal.fire('Sorry!', 'Pool cancell time ended', 'warning');
                                            }}
                                          >
                                            Cancel
                                          </Dropdown.Item>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan={9} className='text-center'>
                          <span className='text-light text-center'>No Record Found</span>
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
             
              </Card.Body>
            )}
          </Card>
          {pools && pools?.length > 0 ? (
            pagination && (
              <Pagination
                className='m-3'
                defaultCurrent={1}
                pageSize // items per page
                current={pagination.page} // current active page
                total={pagination.pages} // total pages
                onChange={searchDoc}
                locale={localeInfo}
              />
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      {show && <ApproveReject show={show} handleModal={handleModal} currentPoolData={currentPoolData} />}
      {scamShow && (
        <Modal
          show={scamShow}
          className='d-flex align-items-center product-modal'
          onHide={() => {
            setScamShow(false);
          }}
        >
          <Form onSubmit={e => markScam(e)}>
            <Modal.Header closeButton>
              <Modal.Title>Mark Scam</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' value={scamState?.title} disabled />
              </Form.Group>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  style={{ resize: 'none' }}
                  as='textarea'
                  type='text'
                  rows='3'
                  placeholder='Message'
                  value={scamState.message}
                  onChange={e => {
                    setScamState({ ...scamState, message: e.target.value });
                  }}
                  required
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='btn-light-blue'
                onClick={e => {
                  setScamShow(false);
                }}
              >
                Cancel
              </Button>
              <Button className='btn-blue' type='submit'>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      {loading ? <ModalLoading /> : <></>}
      {unScamShow && (
        <Modal
          show={unScamShow}
          className='d-flex align-items-center product-modal'
          onHide={() => {
            setUnScamShow(false);
          }}
        >
          <Form onSubmit={e => unMarkScam(e)}>
            <Modal.Header closeButton>
              <Modal.Title>UnMark Scam</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' value={scamState?.title} disabled />
              </Form.Group>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Message</Form.Label>
                <Form.Control style={{ resize: 'none' }} as='textarea' type='text' rows='3' placeholder='Message' value={scamState.message} disabled />
              </Form.Group>
              <Form.Group className='switch-wrapper mb-3'>
                <div className='d-flex align-items-center'>
                  <span className='mt-3 me-4 text-light'><b>Status:</b></span>
                  <div className='switch mt-3'>
                  <input
                    id='switch-1'
                    type='checkbox'
                    className='switch-input mt-3'
                    checked={scamState.scamStatus ? true : false}
                    onChange={e => {
                      setScamState({
                        ...scamState,
                        scamStatus: e.target.checked,
                      });
                    }}
                  />
                  <label htmlFor='switch-1' className='switch-label'>
                  </label>
                </div>
                </div>
                <small style={{color:"yellow"}} className='mt-5'>Note: By changing status pool is marked scam or uncam</small>
                {/* <Form.Label>Status</Form.Label> */}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='btn-light-blue'
                onClick={e => {
                  setUnScamShow(false);
                }}
              >
                Cancel
              </Button>
              <Button className='btn-blue' type='submit'>
                UnScam
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      <div>
        <Modal
          show={whitListShow}
          className='d-flex align-items-center product-modal'
          onHide={() => {
            setWhitListShow(false);
          }}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>White List</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-light'>
            <div className='select-dropdown-wrapper'>
              <Form.Label className='me-3 mb-3'>Select WhiteList Method:</Form.Label>
              <div className='whitelist-content'>
                <Button
                  className='whitelist-content-info d-flex align-items-center justify-content-between'
                  onClick={e => {
                    setDocLoading(true);
                    setMethodType('add');
                    setTimeout(() => setDocLoading(false), 3000);
                  }}
                >
                  <h6>Add Users</h6>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  className='whitelist-content-info d-flex align-items-center justify-content-between'
                  onClick={e => {
                    setDocLoading(true);
                    setMethodType('remove');
                    setTimeout(() => setDocLoading(false), 3000);
                  }}
                >
                  <h6>Remove Users</h6>
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
                <Button
                  className='whitelist-content-info d-flex align-items-center justify-content-between'
                  onClick={e => {
                    setDocLoading(true);
                    setMethodType('saletype');
                    setTimeout(() => setDocLoading(false), 3000);
                  }}
                >
                  <h6>Change Sale Type</h6>
                  <FontAwesomeIcon icon={faTags} />
                </Button>
              </div>
            </div>
            {methodType === 'add' && docLoading === false ? (
              <>
                <Form.Group className='mb-3 input-wrapeper'>
                  <Form.Label>Add Users</Form.Label>
                  <Form.Control
                    style={{ resize: 'none' }}
                    as='textarea'
                    type='text'
                    rows='3'
                    placeholder='
                        Enter the address, using a comma to separate each address.
                        EX:
                        0x968136BB860D9534aF1563a7c7BdDa02B1A979C2,0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1'
                    value={whiteListUsersDoc}
                    onChange={e => {
                      setWhiteListUsersDoc(e.target.value);
                    }}
                    required
                  />
                </Form.Group>
              </>
            ) : methodType === 'remove' && docLoading === false ? (
              <>
                <Form.Group className='mb-3 input-wrapeper'>
                  <Form.Label>Remove Users</Form.Label>
                  <Form.Control
                    style={{ resize: 'none' }}
                    as='textarea'
                    type='text'
                    rows='3'
                    placeholder='
                        Enter the address, using a comma to separate each address.
                        EX:
                        0x968136BB860D9534aF1563a7c7BdDa02B1A979C2,0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1'
                    value={whiteListUsersDoc}
                    onChange={e => {
                      setWhiteListUsersDoc(e.target.value);
                    }}
                  />
                </Form.Group>
              </>
            ) : methodType === 'saletype' && docLoading === false ? (
              <>
                <Form.Group className='d-flex flex-column'>
                  <Form.Label>Sale Type</Form.Label>
                  <div className=' d-flex justify-content-around'>
                    <Form.Group className='mb-md-5 mb-4 d-flex flex-column' controlId='poolStartDate11'>
                      <div className='radio'>
                        <label>
                          <input
                            type='radio'
                            value='public'
                            checked={poolType === 'public'}
                            onChange={e => {
                              setPoolType(e.target.value);
                            }}
                          />
                          <b> Public </b>
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className='mb-md-5 mb-4 d-flex flex-column' controlId='poolStartDate11'>
                      <div className='radio'>
                        <label>
                          <input
                            type='radio'
                            value='whiteList'
                            checked={poolType === 'whiteList'}
                            onChange={e => {
                              setPoolType(e.target.value);
                            }}
                          />
                          <b> WhiteList </b>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </Form.Group>
              </>
            ) : docLoading === true ? (
              <>
                <div className='text-center faq-loader'>
                  <TailSpin height='30' width='30' color='#46bdf4' ariaLabel='loading' />
                </div>
              </>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='btn-light-blue'
              onClick={e => {
                setWhitListShow(false);
                setWhiteListAddresses('');
              }}
            >
              Cancel
            </Button>
            <Button
              className='btn-blue'
              onClick={e => whitListHandler(e, whiteListAddressesData?.whiteListUsers, methodType, whiteListAddressesData?.merkleTree, whiteListAddressesData?.transactionHash)}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ManagePools;
