export const ENV = {
  // serverUrl: process.env.REACT_APP_SERVER_URL_LIVE,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  serverUrlLive: process.env.REACT_APP_SERVER_URL_LIVE,
};

export const bscTestnet = {
  id: 97,
  name: 'BSC Chain Testnet',
  network: 'bsc-test',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Coin',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },
  blockExplorers: {
    default: {
      name: 'Binance Smart Chain Testnet Scan',
      url: 'https://testnet.bscscan.com',
    },
  },
  testnet: true,
};

export const bscMainnet = {
  id: 56,
  name: 'BSC Mainnet',
  network: 'bsc',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Coin',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://bsc-dataseed2.binance.org/',
  },
  blockExplorers: {
    default: {
      name: 'Binance Smart Chain Scan',
      url: 'https://bscscan.com',
    },
  },
  testnet: false,
};

export const platformSupportedChains = {
  // BSC TESTNET
  97: {
    name: 'BSC Testnet',
    logo: 'https://dex-bin.bnbstatic.com/static/images/logo_BNB_Chain.svg',
    poolContract: '0x6faFD24F6d186C7E0a6AED2926D51f3321624C6f',
    stableCoins: [
      { name: 'USDC', address: '0x16227D60f7a0e586C66B005219dfc887D13C9531', decimal: 6 },
      { name: 'USDT', address: '0xA11c8D9DC9b66E209Ef60F0C8D969D3CD988782c', decimal: 6 },
      { name: 'DAI', address: '0x50C4F5C0e6D225f1f0CA451776E7e681151E083a', decimal: 18 },
      { name: 'BUSD', address: '0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47', decimal: 18 },
    ],
    blockExplorers: {
      default: {
        name: 'Binance Smart Chain Testnet Scan',
        url: 'https://testnet.bscscan.com',
      },
    },
    nativeCurrency: {
      decimals: 18,
      name: 'Binance Coin',
      symbol: 'BNB',
    },
    rpcUrls: {
      default: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    }
  },
  //  Goerli Network - Eth
  5: {
    name: 'Goerli Testnet',
    logo: 'https://trustpad.io/_next/static/media/ethereum-logo-full.1d48af6f.png',
    poolContract: '0x057507edA5242a0fe08142d6258F30F18ddfb626',
    stableCoins: [
      { name: 'USDT', address: '0x49F01F27490493959e22952506b4AE5B3BC984eA', decimal: 6 },
      { name: 'USDC', address: '0x36AeEfd0e31AB6772328a7C195757CA2B53804F2', decimal: 6 },
      { name: 'DAI', address: '0xc3228ecb3f9bdd966817e9d4c1f848fb261579fe', decimal: 18 },
      { name: 'BUSD', address: '0xb20109071b4Fc8C35eF51e7Dd6D9FCC9e79E53ee', decimal: 18 },
    ],
    blockExplorers:{
      name:'EtherScan',
      url:'https://goerli.etherscan.io/'
    },
    nativeCurrency: {
      decimals: 18,
      name: 'Ether Coin',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: 'https://rpc.ankr.com/eth_goerli	',
    }
  },
};

export const objectToQueryString = (body)=>{
  const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
  return qs;
}