/* eslint-disable */
import React from 'react';
import { useState, useEffect } from 'react';
import Logo from '../assets/images/MutoPad.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { faBars, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Container, Nav, Button, Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
// import useGetOwner from '../CustomHooks/GetOwner'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cmsPageListing } from '../redux/cms/cms.action';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Alerts from './Alerts/alert';
import { useAccount } from 'wagmi';
import io from 'socket.io-client';
import { apiHelper } from '../redux/apiHelper';
import moment from 'moment';
const socket = io.connect('http://localhost:5000');

const Header = () => {
  const owner = process.env.REACT_APP_OWNER_ADDRESS;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cmsPages = useSelector(state => state?.cms.cmsPageList);
  const [acc, setAcc] = useState('Connect Wallet');
  const [wallet, setWallet] = useState('');
  const [cookies, setCookies, removeCookie] = useCookies(['address']);
  const location = useLocation();
  // const pathname = location.pathname
  const { pathname, search } = location;
  const { address } = useAccount();
  const [alert, setAlert] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum) {
      if (address === undefined || address === '') {
        // AlertNotify("Connect Your Metamask", 3000);
      }
    } else {
      AlertNotify(`Metamask is not Installed.Install metamask from ${'https://chrome.google.com/webstore/detail/metamask'}`, 10000);
    }
  }, [address]);

  const AlertNotify = (message, time) => {
    setAlert(message);
    setTimeout(() => {
      setAlert('');
    }, time);
  };

  useEffect(() => {
    if (address !== undefined && address !== '') {
      let wall = convertToSubString(address);
      setAcc(wall);
      setCookies('address', address);
    }
    setWallet(address);
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    if ((address === undefined && (wallet != '' || wallet != undefined)) || (address === '' && (wallet != '' || wallet != undefined))) {
      removeCookie('address');
      setAcc('Connect Wallet');
      navigate('/');
    }
  }, [address, removeCookie]);

  useEffect(() => {
    dispatch(cmsPageListing());
  }, [dispatch]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const convertToSubString = arg => {
    var res = arg.substring(0, 2);
    var res2 = arg.substring(38, 42);
    const wall = res + '...' + res2;
    return wall;
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = e => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 20 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  const handleLogoScroll = () => {
    window.scroll(0, 0);
  };

  const fetchNotifications = async () => {
    let res = await apiHelper('get', `/v1/admin/notifications/list`);
    if (res) {
      setEventData(res?.data);
    }
  };

  useEffect(() => {
    socket.on('poolCreated', data => {
      fetchNotifications()
    });
  }, [socket]);

  useEffect(() => {
    socket.on('poolupdated', data => {
      fetchNotifications()
    });
  }, [socket]);

  return (
    <>
      <header className='header-section'>
        {alert !== '' ? <Alerts message={alert} show={true} /> : <></>}
        <Navbar className='navbar navbar-expand-lg navbar-light' expand='lg'>
          <Container fluid className='custom-block header-navbar'>
            <Navbar.Brand>
              <Link to='/'>
                <figure className='mb-0'>
                  <span onClick={handleLogoScroll}>
                    <img src={Logo} className='img-fluid' alt='SiteLogo' style={{ height: 'auto', width: 'auto' }} />
                  </span>
                </figure>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav'>
              <FontAwesomeIcon icon={faBars} />
            </Navbar.Toggle>
            <Navbar.Collapse className='collapse navbar-collapse' id='basic-navbar-nav'>
              <Nav className='m-auto navbar-nav'>
                {address ? (
                  <>
                    <Link to='/admin/dashboard' className={pathname === '/admin/dashboard' ? 'current' : ''}>
                      <span onClick={handleLogoScroll}>{owner === address ? 'Admin Dashboard' : 'Dashboard'}</span>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
                <Link
                  to='/?id=pools'
                  className={pathname + `${search}` === '/?id=pools' ? 'current' : ''}
                  onClick={() => {
                    window.scrollTo(0, 1400);
                  }}
                >
                  <span>Pools</span>
                </Link>
                <Link to='/?id=trending' className={pathname + `${search}` === '/?id=trending' ? 'current' : ''}>
                  <span>Trending </span>
                  <strong className='fire-icon'>🔥</strong>
                </Link>
                <Link to='/faqs' className={pathname === '/faqs' ? 'current' : ''}>
                  <span onClick={handleLogoScroll}>FAQs</span>
                </Link>
                {cmsPages.length > 0 &&
                  cmsPages
                    .filter(res => res.pageStatus === true && res.showLinkInHeader === true)
                    .map((link, index) => (
                      <Link to={`cms/${link.pageTitle}`} key={index} className={pathname === `/cms/${link.pageTitle}` ? 'current' : ''}>
                        <span className='text-capitalize'>{link.pageTitle?.split('-').join(' ')}</span>
                      </Link>
                    ))}
              </Nav>
            </Navbar.Collapse>
            <div className='d-flex align-items-center mobile-tabs-handle'>
              {address === process.env.REACT_APP_OWNER_ADDRESS ? (
                <>
                  {/* <div className='d-lg-block me-4 '> */}
                  <div className='position-relative notification-header me-3'>
                    <Button onClick={() => setModalShow(true)}>
                      <FontAwesomeIcon className='notification-icon' icon={faBellSlash} name='' /> <span>({eventData?.data?.notifications?.length || 0})</span>
                    </Button>
                  </div>
                  {/* </div> */}
                </>
              ) : (
                <></>
              )}
              <div className='block-btn'>
                {/* <button className='d-inline light-blue-btn text-capitalize' onClick={walletConnect}>
                {acc}
              </button> */}
                <ConnectButton label='Connect Wallet' showBalance={true} />
              </div>
            </div>
          </Container>
        </Navbar>
      </header>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        className='product-modal d-flex align-items-center'
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notifications Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-content-scroll'>
            {eventData?.data?.notifications && eventData?.data?.notifications?.length > 0 ? (
              eventData?.data?.notifications?.map((data, index) => {
                return (
                  <>
                    <div className='d-flex  align-items-center notification-modal-content mb-2'>
                      <span className='me-2 modal-img'>
                        <img src={`https://ipfs.io/ipfs/${data?.logo}`} height='50' width='50'></img>
                      </span>
                      <span className='me-2'> {data?.title}</span>
                      <div className=''>
                        <span>🤷: 0x...{data?.createdBy?.substring(39, 42)}</span>
                        <span>⏰: {moment(data?.createdAt).format('YY-MM-DD HH:MM')}</span>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <span>Not Any Notification</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn-light-blue text-capitalize'
            type='submit'
            onClick={() => {
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
